import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 16,
        alignItems: 'stretch',
    },
    messageRow: {
        marginVertical: 8,
        flexDirection: 'row',
        alignItems: 'center',
    },
    message: {
        flex: 1,
        padding: 10,
    },
    wrong_link: {
        textAlign: 'center',
        fontSize: 17,
    },
    bookRow: {
        marginVertical: 8,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    title: {
        marginLeft: 8,
        flexShrink: 1,
        color: AppColor.darkTextColor,
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'left',
    },
    contacts: {
        flex: 1,
    },
    urlRow: {
        flex: 1,
        flexDirection: 'row',
    },
    url: {
        marginVertical: 16,
        fontSize: 17,
        flex: 1,
    },
    copy: {
        width: 24,
        height: 24,
        size: 24,
        margin: 16,
    },
    userInfo: {
        margin: 4,
    },
    commandView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginVertical: 20,
    },
    downloadView: {
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        marginVertical: 20,
    },
});

export default styles;
