import {StyleSheet} from 'react-native';
import AppColor from '../classes/appColor';

export const AppScreenLayout = {
    marginHorizontal: 16,
};

const AppStyles = StyleSheet.create({
    screen: {
        margin: 16,
        flex: 1,
        backgroundColor: AppColor.backgroundColor,
    },
    fullScreen: {
        flex: 1,
        backgroundColor: AppColor.backgroundColor,
    },
    buttonPrimary: {
        backgroundColor: AppColor.primaryColor,
    },
    link: {
        color: '#0066FF',
    },
    textCenter: {
        textAlign: 'center',
    },
    textLeft: {
        textAlign: 'left',
    },
    textVCenter: {
        textAlignVertical: 'center',
    },
    textVTop: {
        textAlignVertical: 'top',
    },
    redBox: {
        backgroundColor: '#E39695',
        flex: 1,
    },
    red: {
        backgroundColor: '#ff8f8f',
    },
    blue: {
        backgroundColor: '#C9D7F8',
    },
    appleBlue: {
        backgroundColor: '#0066FF',
    },
    bgRed: {
        backgroundColor: '#ff8f8f',
    },
    bgBlue: {
        backgroundColor: '#C9D7F8',
    },
    bgGreen: {
        backgroundColor: '#00ff00',
    },
    bgGray: {
        backgroundColor: '#888888',
    },
    bgLightGray: {
        backgroundColor: '#dddddd',
    },
    bgLightPink: {
        backgroundColor: '#fff0e8',
    },
    bgWhite: {
        backgroundColor: '#ffffff',
    },
    cTransparent: {
        color: '#00000000',
    },
    cWhite: {
        color: 'white',
    },
    cLightGray: {
        color: '#dddddd',
    },
    cGray: {
        color: '#888888',
    },
    cDark: {
        color: '#3f3f3f',
    },
    cBlack: {
        color: 'black',
    },
    cRed: {
        color: '#ff8f8f',
    },
    cGreen: {
        color: '#00ff00',
    },
    cBlue: {
        color: '#0066FF',
    },
    cOrange: {
        color: '#fd6a02',
    },
    cYellow: {
        color: '#ffee02',
    },
    violet: {
        backgroundColor: 'rgba(206,195,217,0.53)',
    },
    fweight_bold: {
        fontWeight: 'bold',
    },
    fweight_normal: {
        fontWeight: 'normal',
    },
    pd20: {
        padding: 20,
    },
    pd10: {
        padding: 10,
    },
    pd8: {
        padding: 8,
    },
    pd4: {
        padding: 4,
    },
    pd0: {
        padding: 0,
    },
    pdt40: {
        paddingTop: 40,
    },
    pdt20: {
        paddingTop: 20,
    },
    pdt10: {
        paddingTop: 10,
    },
    pdt8: {
        paddingTop: 8,
    },
    pdt4: {
        paddingTop: 4,
    },
    pdb40: {
        paddingBottom: 40,
    },
    pdb30: {
        paddingBottom: 30,
    },
    pdb20: {
        paddingBottom: 20,
    },
    pdb10: {
        paddingBottom: 10,
    },
    pdb8: {
        paddingBottom: 8,
    },
    pdb4: {
        paddingBottom: 4,
    },
    mg40: {
        margin: 40,
    },
    mg20: {
        margin: 20,
    },
    mg10: {
        margin: 10,
    },
    mg8: {
        margin: 8,
    },
    mg4: {
        margin: 4,
    },
    mg2: {
        margin: 2,
    },
    mg1: {
        margin: 1,
    },
    mg0: {
        margin: 0,
    },
    mgx20: {
        marginLeft: 20,
        marginRight: 20,
    },
    mgx16: {
        marginLeft: 16,
        marginRight: 16,
    },
    mgx10: {
        marginLeft: 10,
        marginRight: 10,
    },
    mgx8: {
        marginLeft: 8,
        marginRight: 8,
    },
    mgx4: {
        marginLeft: 4,
        marginRight: 4,
    },
    mgx0: {
        marginLeft: 0,
        marginRight: 0,
    },
    mgy20: {
        marginTop: 20,
        marginBottom: 20,
    },
    mgy10: {
        marginTop: 10,
        marginBottom: 10,
    },
    mgy8: {
        marginTop: 8,
        marginBottom: 8,
    },
    mgy4: {
        marginTop: 4,
        marginBottom: 4,
    },
    mgt80: {
        marginTop: 80,
    },
    mgt60: {
        marginTop: 60,
    },
    mgt40: {
        marginTop: 40,
    },
    mgt30: {
        marginTop: 30,
    },
    mgt20: {
        marginTop: 20,
    },
    mgt10: {
        marginTop: 10,
    },
    mgt8: {
        marginTop: 8,
    },
    mgt4: {
        marginTop: 4,
    },
    mgl20: {
        marginLeft: 20,
    },
    mgl16: {
        marginLeft: 16,
    },
    mgl10: {
        marginLeft: 10,
    },
    mgl8: {
        marginLeft: 8,
    },
    mgl4: {
        marginLeft: 4,
    },
    mgl0: {
        marginLeft: 0,
    },
    mgr20: {
        marginRight: 20,
    },
    mgr16: {
        marginRight: 16,
    },
    mgr10: {
        marginRight: 10,
    },
    mgr8: {
        marginRight: 8,
    },
    mgr4: {
        marginRight: 4,
    },
    mgr2: {
        marginRight: 2,
    },
    mgb30: {
        marginBottom: 30,
    },
    mgb20: {
        marginBottom: 20,
    },
    mgb10: {
        marginBottom: 10,
    },
    mgb8: {
        marginBottom: 8,
    },
    mgb4: {
        marginBottom: 4,
    },
    fsize11: {
        fontSize: 11,
    },
    fsize12: {
        fontSize: 12,
    },
    fsize13: {
        fontSize: 13,
    },
    fsize14: {
        fontSize: 14,
    },
    fsize15: {
        fontSize: 15,
    },
    fsize16: {
        fontSize: 16,
    },
    fsize17: {
        fontSize: 17,
    },
    fsize18: {
        fontSize: 18,
    },
    fsize20: {
        fontSize: 20,
    },
    opacity00: {
        opacity: 0.0,
    },
    opacity025: {
        opacity: 0.25,
    },
    opacity03: {
        opacity: 0.3,
    },
    opacity05: {
        opacity: 0.5,
    },
    opacity07: {
        opacity: 0.7,
    },
    opacity085: {
        opacity: 0.85,
    },
    square00: {
        width: 0,
        height: 0,
    },
    square10: {
        width: 10,
        height: 10,
    },
    square20: {
        width: 20,
        height: 20,
    },
    square24: {
        width: 24,
        height: 24,
    },
    square28: {
        width: 28,
        height: 28,
    },
    square32: {
        width: 32,
        height: 32,
    },
    square36: {
        width: 36,
        height: 36,
    },
    square44: {
        width: 44,
        height: 44,
    },
    square100: {
        width: 100,
        height: 100,
    },
    square200: {
        width: 200,
        height: 200,
    },
    square400: {
        width: 400,
        height: 400,
    },
    width20: {
        width: 20,
    },
    width32: {
        width: 32,
    },
    width44: {
        width: 44,
    },
    width80: {
        width: 80,
    },
    width100: {
        width: 100,
    },
    width120: {
        width: 120,
    },
    width140: {
        width: 140,
    },
    width200: {
        width: 200,
    },
    widthFull: {
        width: '100%',
    },
    height0: {
        height: 0,
    },
    height20: {
        height: 20,
    },
    height24: {
        height: 24,
    },
    height44: {
        height: 44,
    },
    height64: {
        height: 64,
    },
    height80: {
        height: 80,
    },
    height100: {
        height: 100,
    },
    height120: {
        height: 120,
    },
    height140: {
        height: 140,
    },
    height200: {
        height: 200,
    },
    height400: {
        height: 400,
    },
    height600: {
        height: 600,
    },
    whEqual: {
        aspectRatio: 1,
    },
    posAbsolute: {
        position: 'absolute',
    },
    resizeContain: {
        resizeMode: 'contain',
    },
    resizeCover: {
        resizeMode: 'cover',
    },
    fullInParent: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    centerInParent: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    flex0: {
        flex: 0,
    },
    flex1: {
        flex: 1,
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    gridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
    },
    flexC_center: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    flexC_start: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
    },
    flexC_stretch: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-evenly',
    },
    flexR_start: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
    },
    flexR_alignRight: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    flexR_center: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    flexR_stretch: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-evenly',
    },
    JCCenter: {
        justifyContent: 'center',
    },
    JCStart: {
        justifyContent: 'flex-start',
    },
    JCBetween: {
        justifyContent: 'space-between',
    },
    AlignSelfCenter: {
        alignSelf: 'center',
    },
    AlignSelfStretch: {
        alignSelf: 'stretch',
    },
    AlignItemsCenter: {
        alignItems: 'center',
    },
    bRadius0: {
        borderRadius: 0,
    },
    bRadius2: {
        borderRadius: 2,
    },
    bRadius3: {
        borderRadius: 3,
    },
    bRadius4: {
        borderRadius: 4,
    },
    bRadius6: {
        borderRadius: 6,
    },
    bRadius8: {
        borderRadius: 8,
    },
    bRadius16: {
        borderRadius: 16,
    },
    bRadius24: {
        borderRadius: 24,
    },
    bRadius32: {
        borderRadius: 32,
    },
    borderWidth0: {
        borderWidth: 0,
    },
    borderWidth1: {
        borderWidth: 1,
    },
    border1: {
        borderWidth: 1,
        borderColor: AppColor.dark_gray,
    },
    mainText: {
        marginTop: 20,
        fontSize: 14,
    },
    Header: {
        fontFamily: 'HelveticaNeue-Bold',
        fontSize: 23,
        color: AppColor.darkPrimaryColor,
        marginVertical: 25,
    },
    scroll: {
        flex: 1,
        backgroundColor: AppColor.backgroundColor,
    },
    popup_container: {
        alignItems: 'stretch',
        width: 320,
        backgroundColor: AppColor.white,
        shadowColor: AppColor.mediumGray,
        shadowOpacity: 0.6,
        shadowRadius: 2,
        shadowOffset: {
            height: 1,
            width: 1,
        },
    },
    primaryButton: {
        margin: 10,
        paddingHorizontal: 25,
        paddingVertical: 15,
    },
});

export default AppStyles;
