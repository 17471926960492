import { Player } from './player';

export enum Language {
    none = '',
    english = 'en',
    chinese = 'zh'
}

export interface BookItem {
    name: string;
    icon: string;
    playMenuId: number;
    supportedLocales?: Language[],
    seriesId: number,
    role?: any,
    level: number
}

export interface BookList {
    name: string;
    icon: string;
    language?: Language;
    author?: Player;
    authorId: number;
    items: BookItem[];
}

export interface Invitation {
    title: string;
    message: string;
    icon: string;
    language?: Language;
    fromUser?: Player;
    toUserIds?: number[];
    time?: string;
}

export interface BookListInvitation extends Invitation {
    bookList: BookList;
}
