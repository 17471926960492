import React from 'react';
import {Text} from 'react-native';
import CTouchable from '../Button/CTouchable';
import AppColor from '../../classes/appColor';

interface ClickableTextProps {
    hidden?: boolean;
    title: string;
    style?: any;
    containerStyle?: any;
    testID?: string;
    onPress?: () => void;
    disabled?: boolean;
}

class ClickableText extends React.PureComponent<ClickableTextProps> {
    render() {
        if (this.props.hidden) return null;

        const defaultStyle = {
            color: AppColor.darkPrimaryColor,
            fontSize: 16,
            textAlign: 'center',
            paddingTop: 5,
            paddingHorizontal: 5,
            paddingBottom: 5,
        };

        return (
            <CTouchable style={this.props.containerStyle} testID={this.props.testID} onPress={(!this.props.disabled && this.props.onPress) || undefined}>
                <Text style={[defaultStyle, this.props.style]}>{this.props.title}</Text>
            </CTouchable>
        );
    }
}

export default ClickableText;
