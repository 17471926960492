import React from 'react';
import {Text, View} from 'react-native';
import Popup from './popup';
import styles from './alertPopup_styles';
import CButton from '../Button/CButton';
import {BaseComponent} from '../Base/baseComponent';
import AppStyles from '../../screens/appStyles';

export interface AlertProps {
    title: string;
    message: string;
    buttons: any[];
    options: any;
}

export interface AlertPopupProps {
    content?: AlertProps;
    onClose?: () => void;
}

class AlertPopup extends BaseComponent<AlertPopupProps> {
    getButtonView(buttons: any[]) {
        return buttons.map((button: any, index: number) => (
            <CButton
                key={index}
                small={true}
                testID={'alertButton_' + index}
                title={button.text}
                onPress={() => {
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                    button.onPress();
                }}
            />
        ));
    }

    render() {
        if (!this.props.content) {
            return null;
        }
        const content: AlertProps = this.props.content;
        return (
            <Popup visible={true} center={true} backdropOpacity={0.5}>
                <View>
                    <View style={AppStyles.flexR_center}>
                        <Text style={styles.title}>{content.title}</Text>
                    </View>
                    <Text style={styles.content}>{content.message}</Text>
                    <View style={[AppStyles.flexR_center, AppStyles.mgt20]}>{this.getButtonView(content.buttons)}</View>
                </View>
            </Popup>
        );
    }
}

export default AlertPopup;
