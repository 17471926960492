import { ScrollView } from 'react-native'
import styles from './styles';
import React from 'react';
import BaseScreen from '../Base/baseScreen';
import CForm from '../../components/Form/CForm';
import CButton from '../../components/Button/CButton';
import AppStyles from '../appStyles';
import Utils from '../../utils/utils';
import ServerModule from '../../modules/serverModule';
import {BannerType} from '../../components/Banner/CBanner';
import Input from '../../components/Input/CInput';
import {LocalesModule} from '../../modules/LocalesModule';

class PasswordResetScreen extends BaseScreen {
    state: {[id: string]: any} = {
        enableSubmit: false,
    };
    fields: any = {};

    constructor(props: any) {
        super(props);
        this.title = LocalesModule.login.password_reset;
        this.fields.email = this.props.route.params?.email;
        this.validateField('', this.fields.email).then((result) => {
            this.state.enableSubmit = result ? false : true;
            this.setState(this.state);
        });
        this.widthLimited = true;
    }

    validateField(_field: string, value: any): Promise<string | undefined> {
        let result = undefined;
        if (!Utils.validateEmail(value)) {
            result = LocalesModule.login.message.invalid_email;
        }
        return Promise.resolve(result);
    }

    handleChange(field: string, value: string) {
        let obj = {[field]: value};
        this.setState(obj);
    }

    onAllFieldsValidated(bValid: boolean) {
        this.state.enableSubmit = bValid;
        this.setState(this.state);
    }

    handleSubmit() {
        console.log('password reset submit.');
        ServerModule.apiCall('user/passwordReset', 'POST', {
            email: this.fields.email,
        }).then((json) => {
            console.log('password reset result: ', json);
            if (json.error) {
                this.showBanner(BannerType.error, json.error);
            } else {
                const infoObj = {
                    banner: {
                        type: BannerType.success,
                        text: LocalesModule.login.message.new_password,
                    },
                };
                this.navigateBack('login', infoObj);
            }
        });
    }

    getMainView() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <CForm
                    fieldsValue={this.fields}
                    validateField={this.validateField.bind(this)}
                    onAllFieldsValidated={(valid: boolean) => this.onAllFieldsValidated(valid)}
                >
                    <Input
                        placeholder={LocalesModule.signup.fields.email}
                        field={'email'}
                    />
                </CForm>
                <CButton
                    primary={true}
                    style={AppStyles.mgt40}
                    disabled={!this.state.enableSubmit}
                    onPress={() => this.handleSubmit()}
                    title={LocalesModule.login.reset}
                />
            </ScrollView>
        );
    }
}

export default PasswordResetScreen;
