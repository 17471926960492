import React from 'react';
import { View } from 'react-native';
//import PlatformModule from '../../modules/PlatformModule';
import styles from './NavigationBar_styles';
import CNavigationButton from '../Button/CNavigationButton';
import AppStyles from '../../screens/appStyles';
import CImage from '../Image/CImage';
import BaseScreen from '../../screens/Base/baseScreen';
import HideableView from '../View/HideableView';
import Label from '../Text/Label';

interface NavigationBarProps {
    style?: any;
    hidden?: boolean;
    parentScreen: BaseScreen
}

export const getNavigationLeftView = (parentScreen: BaseScreen) => {
    return (
        <CNavigationButton
            onPress={() => {
                parentScreen.onBackClicked();
            }}
            testID={'btnNavigationLeft'}
            whiteTheme={false}
            config={parentScreen?.leftButton}
        />
    );
}

export const getNavigationRightView = (parentScreen: BaseScreen) => {
    return (
        <View style={AppStyles.flexR_center}>
            <CImage
                onPress={() => {
                    parentScreen?.onNavigationRightSecondButtonClicked();
                }}
                style={styles.iconNext}
                name={parentScreen?.rightButton.iconNext}
            />
            <CNavigationButton
                onPress={() => {
                    parentScreen?.onNavigationRightButtonClicked();
                }}
                testID={'btnNavigationRight'}
                config={parentScreen?.rightButton}
            />
        </View>
    );
}

export const getTitleView = (screen: BaseScreen) => {
    return (
        <View style={styles.titleView} pointerEvents={'box-none'}>
            <HideableView hidden={!screen?.titleIcon}>
                <CImage
                    style={[styles.titleIcon, screen?.titleIconStyle]}
                    name={screen?.titleIcon}
                    onPress={() => screen?.onTitleIconClick()}
                />
            </HideableView>
            <Label testID={'ScreenTitleText'} style={styles.title}>
                {screen?.title}
            </Label>
        </View>
    );
}

export default function NavigationBar(props: NavigationBarProps) {

    if (props.hidden) {
        return null;
    }
    const style = {
        top: 0,
    }
    return (
        <View style={[styles.navigationBar,style]} pointerEvents={'box-none'}>
            {getNavigationLeftView(props.parentScreen)}
            {getTitleView(props.parentScreen)}
            {getNavigationRightView(props.parentScreen)}
        </View>
    );
}
