import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';

export const styles = StyleSheet.create({
    bar: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    centered: {
        position: 'absolute',
    },
    icon: {
        tintColor: AppColor.headerInActiveTextColor,
    },
});

export default styles;
