import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';
import {NavigationBarHeight} from '../../classes/appContants';

export const styles = StyleSheet.create({
    navigationBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: NavigationBarHeight,
    },
    titleView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        margin: 4,
        color: AppColor.headerTextColor,
    },
    titleIcon: {
        resizeMode: 'cover',
        width: 36,
        height: 36,
        borderRadius: 18,
    },
    iconNext: {
        width: 30,
        height: 30,
        color: AppColor.darkPrimaryColor
    }
});

export default styles;
