// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import iconFont2 from 'react-native-vector-icons/Fonts/EvilIcons.ttf';
import iconFont3 from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import iconFont4 from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFont5 from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import iconFont6 from 'react-native-vector-icons/Fonts/Entypo.ttf';
import iconFont7 from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import iconFont8 from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import iconFont9 from 'react-native-vector-icons/Fonts/Fontisto.ttf';

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: AntDesign;
}`;

const iconFontStyles2 = `@font-face {
  src: url(${iconFont2});
  font-family: EvilIcons;
}`;

const iconFontStyles3 = `@font-face {
  src: url(${iconFont3});
  font-family: MaterialCommunityIcons;
}`;

const iconFontStyles4 = `@font-face {
  src: url(${iconFont4});
  font-family: FontAwesome;
}`;

const iconFontStyles5 = `@font-face {
  src: url(${iconFont5});
  font-family: MaterialIcons;
}`;

const iconFontStyles6 = `@font-face {
  src: url(${iconFont6});
  font-family: Entypo;
}`;

const iconFontStyles7 = `@font-face {
  src: url(${iconFont7});
  font-family: FontAwesome5;
}`;

const iconFontStyles8 = `@font-face {
  src: url(${iconFont8});
  font-family: Ionicons;
}`;

const iconFontStyles9 = `@font-face {
  src: url(${iconFont9});
  font-family: Fontisto;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
    style.styleSheet.cssText =
        iconFontStyles +
        iconFontStyles2 +
        iconFontStyles3 +
        iconFontStyles4 +
        iconFontStyles5 +
        iconFontStyles6 +
        iconFontStyles7 +
        iconFontStyles8 +
        iconFontStyles9;
} else {
    style.appendChild(document.createTextNode(iconFontStyles));
    style.appendChild(document.createTextNode(iconFontStyles2));
    style.appendChild(document.createTextNode(iconFontStyles3));
    style.appendChild(document.createTextNode(iconFontStyles4));
    style.appendChild(document.createTextNode(iconFontStyles5));
    style.appendChild(document.createTextNode(iconFontStyles6));
    style.appendChild(document.createTextNode(iconFontStyles7));
    style.appendChild(document.createTextNode(iconFontStyles8));
    style.appendChild(document.createTextNode(iconFontStyles9));
}

// Inject stylesheet
document.head.appendChild(style);
