import {PureComponent} from 'react';
import {Image} from 'react-native';
import React from 'react';
import PlatformModule from '../../modules/PlatformModule';

//var Grayscale : any = {};
if (!PlatformModule.isWeb()) {
    //Grayscale = require("react-native-color-matrix-image-filters");
}

interface CGrayImageProps {
    style?: any;
    source?: any;
}

export default class CGrayImage extends PureComponent<CGrayImageProps> {
    constructor(props: CGrayImageProps) {
        super(props);
    }

    render() {
        return <Image source={this.props.source} style={this.props.style} />;
    }

    // render() {
    //     return ( <Grayscale>
    //                 <Image source={this.props.source} style={ this.props.style }/>
    //             </Grayscale>)
}
