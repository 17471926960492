import React from 'react';
import { Image, ImageBackground } from 'react-native'
import CIcon from '../Icon/CIcon';
import CTouchable from '../Button/CTouchable';
import SwitchView from '../View/SwitchView';
import CGrayImage from './CGrayImage';
import {ShortUrlID} from '../../classes/shortUrl';
import CacheModule from '../../modules/cacheModule';

export interface CImageProps {
    hidden?: boolean;
    grayScale?: boolean;
    background?: boolean;
    name?: any;
    style?: any;
    onPress?: (event?: any) => void;
    onLoadEnd?: () => void;
    defaultImage?: string;
    resizeMode?: string;
    activeOpacity?: number;
    testID?: string;
    children?: React.ReactNode;
}

class CImage extends React.PureComponent<CImageProps> {
    private error: boolean = false;

    private convertStyle(style: any): any {
        let newStyle = style;
        if (style && (style.resizeMode || style.width || style.height)) {
            const {resizeMode, ...iconStyle} = style;
            newStyle = iconStyle;
            if (iconStyle.width || iconStyle.height) {
                const size = iconStyle.width ? iconStyle.width : iconStyle.height;
                newStyle = {fontSize: size * 0.95, ...iconStyle};
            }
        }
        return newStyle;
    }

    private convertToImageStyle(): any {
        let style = this.props.style;
        if (style && (style.color || style.fontSize)) {
            const {color, fontSize, ...imageStyle} = style;
            style = imageStyle;
        }
        return style;
    }

    onError() {
        console.log('CImage onError: ', this.props.name);
        this.error = true;
        this.forceUpdate();
    }

    render() {
        if (this.props.hidden) return null;
        if ((!this.props.name || this.props.name.length == 0) && !this.props.defaultImage) return null;

        const defaultImage = this.props.defaultImage || './FontAwesome/image';
        let name = this.error ? defaultImage : this.props.name;
        if (!this.props.name || this.props.name.length == 0) {
            name = defaultImage;
        }
        let source : any;
        if (typeof name == 'string') {
            if (name.startsWith(ShortUrlID)) {
                source = CacheModule.getBlobSource(name);
            } else if (name.startsWith('./')) {
                source = name.substring(2);

                let newIconStyle = this.props.style;
                if (Array.isArray(newIconStyle)) {
                    newIconStyle = newIconStyle.map((item) => this.convertStyle(item));
                } else {
                    newIconStyle = this.convertStyle(newIconStyle);
                }
                return <CIcon testID={this.props.testID} onPress={this.props.onPress} style={newIconStyle} name={source} />;
            } else if (name.startsWith('file://')) {
                const style = this.convertToImageStyle();
                return (
                    <CTouchable
                        onPress={this.props.onPress}
                        activeOpacity={this.props.activeOpacity}
                    >
                        <Image
                            testID={this.props.testID}
                            source={{uri: name}}
                            style={style}
                            onLoadEnd={this.props.onLoadEnd}
                            onError={this.onError.bind(this)}
                        />
                    </CTouchable>
                );
            } else {
                source = {
                    uri: name,
                }
            }
        } else {
            source = name;
        }
        let style = this.convertToImageStyle();
        const imageTestId = this.props.onPress ? undefined : this.props.testID;
        return (
            <CTouchable
                testID={this.props.testID}
                onPress={this.props.onPress}
                style={style}
                activeOpacity={this.props.activeOpacity}
            >
                <SwitchView first={this.props.grayScale === true}>
                    <CGrayImage source={source} style={style} />
                    <SwitchView first={!!this.props.background}>
                        <ImageBackground testID={imageTestId} source={source} style={style}>
                            {this.props.children}
                        </ImageBackground>
                        <Image
                            testID={imageTestId}
                            source={source}
                            style={style}
                            onLoadEnd={this.props.onLoadEnd}
                            onError={this.onError.bind(this)}
                        />
                    </SwitchView>
                </SwitchView>
            </CTouchable>
        );
    }
}

export default CImage;
