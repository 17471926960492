import React from 'react';
import { View, Text } from 'react-native';
import CTouchable from '../../components/Button/CTouchable'
import CImage from '../../components/Image/CImage'
import IconModule from '../../modules/IconModule';
import { DefaultFont } from '../../classes/appContants';
import { User } from '../../classes/user';
import AppColor from '../../classes/appColor';
import { Player } from './player'

interface UserInfoMiniComponentProps {
    user?: User;
    player?: Player;
    navigation: any;
    imageStyle?: any;
    textStyle?: any;
    style?: any;
}

export default function UserInfoMiniComponent( props: UserInfoMiniComponentProps ) {
    const user = props.user;
    const player : Player = props.player || ( { id: user?.id, icon: user?.avatar, name: user?.name } as Player );
    if ( !player ) {
        return null;
    }

    return (
        <View style={[{flexDirection: 'row', alignItems: 'center'}, props.style]}>
            <CTouchable
                onPress={() => {
                }}
            >
                <CImage
                    style={[
                        {
                            width: 34,
                            height: 34,
                            resizeMode: 'cover',
                            borderRadius: 17,
                        },
                        props.imageStyle,
                    ]}
                    name={IconModule.getUserIcon(player.icon)}
                />
            </CTouchable>
            <CTouchable
                onPress={() => {
                }}
            >
                <Text
                    style={[
                        {
                            paddingHorizontal: 4,
                            fontFamily: DefaultFont,
                            fontSize: 15,
                            maxWidth: 90,
                            color: AppColor.mediumGray
                        },
                        props.textStyle,
                    ]}
                >
                    {player.name}
                </Text>
            </CTouchable>
        </View>
    );
}
