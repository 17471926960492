import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';
import { DefaultFont } from '../../classes/appContants';

export const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1,
    },
    bookRow: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rowLine: {
        borderBottomColor: AppColor.lightGray,
        borderBottomWidth: 1,
    },
    bookIcon: {
        width: 36,
        height: 36,
    },
    bookName: {
        marginLeft: 8,
        flexShrink: 1,
        color: AppColor.darkTextColor,
        fontSize: 17,
        textAlign: 'left',
    },
    titleRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title_edit: {
        flex: 1,
        margin: 4,
        marginTop: 20,
        flexShrink: 1,
        color: AppColor.darkTextColor,
        fontWeight: 'bold',
        fontSize: 18,
        fontFamily: DefaultFont,
        textAlign: 'left',
        borderWidth: 1,
        borderColor: AppColor.lightGray,
        paddingHorizontal: 5,
        paddingVertical: 10,
    },
    title: {
        margin: 4,
        marginTop: 20,
        color: AppColor.darkTextColor,
        fontWeight: 'bold',
        fontSize: 18,
        fontFamily: DefaultFont,
        textAlign: 'left',
        paddingHorizontal: 5,
        paddingVertical: 10,
    },
    deleteIcon: {
        width: 24,
        height: 24,
        marginLeft: 30,
        marginRight: 10,
        marginTop: 30,
    },
    bookList: {
        alignItems: 'stretch',
        borderWidth: 1,
        borderColor: AppColor.lightGray,
        borderRadius: 4,
        padding: 8,
        margin: 4,
    },
    button: {
        margin: 4,
        marginTop: 10,
        borderRadius: 4,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    addMoreRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
});
