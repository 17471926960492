import React from 'react';
import CButton from '../../components/Button/CButton';
import { ScrollView, Switch, View } from 'react-native'
import styles from './styles';
import BaseScreen from '../Base/baseScreen';
import CForm from '../../components/Form/CForm';
import AppStyles from '../appStyles';
import ClickableText from '../../components/Text/ClickableText';
import Input from '../../components/Input/CInput';
import { LocalesModule } from '../../modules/LocalesModule';
import SignupModule from '../../modules/signupModule';

class SignupScreen extends BaseScreen {
    state: {[id: string]: any} = {
        agree: false,
        allFieldsValid: false,
        userInfo: {},
    };

    constructor(props: any) {
        super(props);
        this.title = LocalesModule.signup.signup;
        this.widthLimited = true;
        this.webTabIndex = 4;
        SignupModule.init();
    }

    onFocus() {
        if (this.props.route.params?.agree) {
            if (this.state.agree != this.props.route.params.agree) {
                this.state.agree = this.props.route.params.agree;
                this.setState(this.state);
            }
        }
    }

    toggleSwitch = (value: boolean) => {
        this.state.agree = value;
        this.setState(this.state);
    };

    onAllFieldsValidated(bValid: boolean) {
        this.state.allFieldsValid = bValid;
        this.setState(this.state);
    }

    onNext() {
        this.navigate('signup2');
    }

    getMainView() {
        return (
            <ScrollView contentContainerStyle={styles.form}>
                <CForm
                    style={AppStyles.flexC_stretch}
                    fieldsValue={SignupModule.fields}
                    validateField={SignupModule.validateField.bind(SignupModule)}
                    onAllFieldsValidated={(valid: boolean) => this.onAllFieldsValidated(valid)}
                >
                    <Input
                        placeholder={LocalesModule.signup.fields.name}
                        field={'name'}
                        autoFocus={true}
                        autoCapitalize="words"
                    />
                    <Input
                        placeholder={LocalesModule.signup.fields.email}
                        field={'email'}
                        autoCapitalize="none"
                        autoCorrect={false}
                    />
                    <Input
                        placeholder={LocalesModule.signup.fields.password}
                        field={'password1'}
                        autoCorrect={false}
                        secureTextEntry={true}
                    />
                    <Input
                        placeholder={LocalesModule.signup.fields.password2}
                        field={'password2'}
                        autoCorrect={false}
                        secureTextEntry
                    />
                </CForm>
                <View style={styles.term}>
                    <Switch onValueChange={this.toggleSwitch} value={this.state.agree}/>
                    <ClickableText
                        onPress={() => this.props.navigation.navigate('agreement')}
                        title={LocalesModule.signup.terms_services}
                    />
                </View>
                <View style={[AppStyles.flexR_center, AppStyles.AlignSelfStretch]}>
                    <CButton
                        primary={true}
                        style={AppStyles.primaryButton}
                        disabled={!this.state.allFieldsValid || !this.state.agree}
                        title={LocalesModule.common.next}
                        onPress={() => this.onNext()}
                    />
                </View>
            </ScrollView>
        );
    }
}

export default SignupScreen;
