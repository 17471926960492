import React from 'react';

export class Ref {
    ref: any;

    constructor() {
        this.ref = React.createRef();
    }

    get component(): any {
        return this.ref.current;
    }
}
