import { StackActions } from '@react-navigation/core'
import React from 'react';

import {ActivityIndicator, View, Text, Alert} from 'react-native';
import { NavigationBarHeight } from '../../classes/appContants'
import Spinner from '../../components/Spinner'
import styles from './baseScreen_styles';
import AppStyles from '../appStyles';
import AppColor from '../../classes/appColor'
import {BaseComponent} from '../../components/Base/baseComponent';
import CBanner, {BannerType, CBannerData} from '../../components/Banner/CBanner';
import CImage from '../../components/Image/CImage';
import CNavigationButton, {CNavigationButtonConfig} from '../../components/Button/CNavigationButton';
import HideableView from '../../components/View/HideableView';
import {NavigationProp} from '@react-navigation/native';
import CButton from '../../components/Button/CButton';
import AlertPopup, {AlertProps} from '../../components/Popup/alertPopup';
import PlatformModule from '../../modules/PlatformModule';
import MaxWidthLimitedView, {
    WebBigScreenWidthLimited,
    WebSmallScreenWidthLimited,
} from '../../components/View/MaxWidthLimitedView';
import WebTopBarComponent from '../Web/webTopBarComponent';
import WebBottomBarComponent from '../Web/webBottomBarComponent';
import {AppInstance} from '../../app';
import {LocalesModule} from '../../modules/LocalesModule';

interface BaseScreenProps {
    navigation: NavigationProp<any>;
    route: any;
}

export class SpinnerConfig {
    text?: string;
    textStyle?: any;
    visible?: boolean;
    overlayColor?: string;
}

class BaseScreen extends BaseComponent<BaseScreenProps> {
    title?: string;
    titleIcon?: string;
    titleIconStyle?: any;

    isLoading = false;
    error?: string;
    spinner = new SpinnerConfig();

    leftButton = new CNavigationButtonConfig();
    rightButton = new CNavigationButtonConfig();
    hideNavigationBar = false;

    banner?: CBannerData;
    focusListener?: any;
    willBlurListener?: any;

    static navigationBackFromOtherScreenParams: any;
    isBackFromOtherScreen: boolean = false;

    alertContent?: AlertProps;
    widthLimited?: boolean;

    webTabIndex = 0;

    constructor(props: any) {
        super(props);
        this.leftButton.icon = './AntDesign/left';
        this.setupNavigationItems();
    }

    setupNavigationItems() {
        const navigation: any = this.props.navigation;
        if (navigation) {
            navigation.title = {screen: this};
            navigation.left = {screen: this};
            navigation.right = {screen: this};
        }
    }

    setState(state: any) {
        super.setState({...state, isLoading: this.isLoading});
    }

    componentDidMount() {
        console.log('componentDidMount:  ', this.title);
        super.componentDidMount();
        this.willBlurListener = this.props.navigation.addListener('blur', this.onBlur.bind(this));
        this.focusListener = this.props.navigation.addListener('focus', this.preDidFocus.bind(this));
    }

    onBlur() {}

    preDidFocus() {
        if (this.props.navigation.isFocused()) {
            this.onFocus();
        }
    }

    onFocus() {
        let params = BaseScreen.navigationBackFromOtherScreenParams;
        this.isBackFromOtherScreen = params ? true : false;
        BaseScreen.navigationBackFromOtherScreenParams = undefined;
        this.props.route.params = {...this.props.route.params, ...params};
        params = this.props.route.params;

        const bannerParams = params && params.banner;
        if (bannerParams && bannerParams.text) {
            this.showBanner(bannerParams.type, bannerParams.text, bannerParams);
            const {banner, ...otherParams} = this.props.route.params;
            this.props.route.params = otherParams;
        } else {
            this.onBannerClose();
        }
    }

    goBack() {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            AppInstance.goHome(this.props.navigation);
        }
    }

    onBackClicked() {
        BaseScreen.navigationBackFromOtherScreenParams = {};
        this.goBack();
    }

    navigateBack(screen?: string, params?: any) {
        BaseScreen.navigationBackFromOtherScreenParams = {...params};
        if (screen) {
            this.navigate(screen, params);
        } else {
            this.goBack();
        }
    }

    navigateBackToTop(type?: BannerType, message?: string) {
        const infoObj = {
            banner: {
                type: type,
                text: message,
                autoDismiss: true,
            },
        };
        BaseScreen.navigationBackFromOtherScreenParams = {...infoObj};
        this.props.navigation.dispatch(StackActions.popToTop());
    }

    navigateBackWithBanner(type: BannerType, message?: string) {
        const infoObj = {
            banner: {
                type: type,
                text: message,
                autoDismiss: true,
            },
        };
        this.navigateBack(undefined, infoObj);
    }

    isNavigateBack() {
        return this.isBackFromOtherScreen;
    }

    onNavigationRightButtonClicked() {}
    onNavigationRightSecondButtonClicked() {}

    setTitle(title: string, titleIcon?: string) {
        this.title = title;
        this.titleIcon = titleIcon;
        this.updateNavigation();
    }

    updateNavigation() {
        const navigation: any = this.props.navigation;
        if (navigation.title && navigation.title.update) {
            navigation.title.update();
        }
        if (navigation.left && navigation.left.update) {
            navigation.left.update();
        }
        if (navigation.right && navigation.right.update) {
            navigation.right.update();
        }
    }

    getTitleView() {
        return (
            <View style={styles.titleView}>
                <HideableView hidden={!this.titleIcon}>
                    <CImage
                        style={[styles.titleIcon, this.titleIconStyle]}
                        name={this.titleIcon}
                        onPress={() => this.onTitleIconClick()}
                    />
                </HideableView>
                <Text testID={'ScreenTitleText'} style={styles.title}>
                    {this.title}
                </Text>
            </View>
        );
    }

    getNavigationBar() {
        const barStyle = PlatformModule.isWeb() ? [styles.titleBar, {backgroundColor: AppColor.headerColor}] : styles.titleBar;

        return (
            <View style={{height: NavigationBarHeight}}>
                <View style={barStyle}>
                    {this.getNavigationLeftView()}
                    {this.getTitleView()}
                    {this.getNavigationRightView()}
                </View>
            </View>
        );
    }

    onTitleIconClick() {
        console.log('onTitleIconClick');
    }

    getNavigationLeftView() {
        return (
            <CNavigationButton
                onPress={() => {
                    this.onBackClicked();
                }}
                testID={'btnNavigationLeft'}
                config={this.leftButton}
            />
        );
    }

    getNavigationRightView() {
        return (
            <CNavigationButton
                onPress={() => {
                    this.onNavigationRightButtonClicked();
                }}
                testID={'btnNavigationRight'}
                config={this.rightButton}
            />
        );
    }

    getErrorView() {
        const styleTop = PlatformModule.isWeb() ? {top: NavigationBarHeight} : {};
        return (
            <View style={[styles.centerInScreen, styleTop]}>
                <Text style={AppStyles.mg20}> {this.error} </Text>
                <CButton margin={10} title={LocalesModule.common.go_back} small onPress={() => this.onBackClicked()}></CButton>
            </View>
        );
    }

    getLoadingView() {
        return (
            <View style={styles.centerInScreen}>
                <ActivityIndicator />
            </View>
        );
    }

    getMainView() {
        return <View style={styles.mainView}></View>;
    }

    getSpinnerView() {
        const text = this.spinner.text != undefined ? this.spinner.text : LocalesModule.common.please_wait;
        const textStyle = this.spinner.textStyle || {color: AppColor.white};

        return (
            <Spinner
                visible={this.spinner.visible}
                overlayColor={this.spinner.overlayColor}
                textContent={text}
                textStyle={textStyle}
            />
        );
    }

    showSpinner(bShow: boolean, text?: string, textStyle?: any) {
        this.spinner.visible = bShow;
        if (text) {
            this.spinner.text = text;
        }
        if (textStyle) {
            this.spinner.textStyle = textStyle;
        }
        this.setState(this.state);
    }

    showBanner(type: BannerType, text: string, params: any = undefined) {
        this.banner = {type: type, text: text, ...params};
        this.setState(this.state);
    }

    showError(error?: string) {
        this.isLoading = false;
        this.error = error ?? LocalesModule.common.general_error;
        this.setState(this.state);
    }

    clearError() {
        this.error = undefined;
    }

    onBannerClose() {
        this.banner = undefined;
        this.setState(this.state);
    }

    getBackgroundView(): any {
        return null;
    }

    showAlert(title: string, message: string, buttons: any[], options?: any) {
        if (PlatformModule.isWeb()) {
            this.alertContent = {title, message, buttons, options};
            this.setState(this.state);
        } else {
            Alert.alert(title, message, buttons, options);
        }
    }

    alert(message: string) {
        this.showAlert(LocalesModule.product_name, message, [
            {
                text: LocalesModule.common.ok,
                onPress: () => {},
            },
        ]);
    }

    hideAlert() {
        this.alertContent = undefined;
        this.setState(this.state);
    }

    getAlertView() {
        if (PlatformModule.isWeb()) {
            return (
                <AlertPopup
                    content={this.alertContent}
                    onClose={() => {
                        this.hideAlert();
                    }}
                />
            );
        } else {
            return null;
        }
    }

    getBannerView(top?: number) {
        const style: any = {
            position: 'absolute',
            top: top ?? 0,
            left: 0,
            right: 0,
        };
        return (
            <View style={style}>
                <MaxWidthLimitedView limitedWidth={this.getLimitedWidth()}>
                    <CBanner data={this.banner} onClose={() => this.onBannerClose()} />
                </MaxWidthLimitedView>
            </View>
        );
    }

    getLimitedWidth() {
        return this.widthLimited === false
            ? undefined
            : this.widthLimited
            ? WebSmallScreenWidthLimited
            : WebBigScreenWidthLimited;
    }

    renderMainView() {
        const backgroundView = this.getBackgroundView();
        const backgroundColorStyle = backgroundView ? undefined : {backgroundColor: AppColor.backgroundColor};

        return (
            <View style={[styles.mainView, backgroundColorStyle]}>
                {this.getMainView()}
                {!PlatformModule.isWeb() && this.getBannerView(backgroundView ? 64 : 0)}
                {this.getAlertView()}
                {this.getSpinnerView()}
                {this.isLoading ? this.getLoadingView() : null}
                {this.error ? this.getErrorView() : null}
            </View>
        );
    }

    renderMaxWidthView() {
        const containerStyles = PlatformModule.isWeb() ? styles.containerWeb : styles.container;
        const backgroundView = this.getBackgroundView();

        return (
            <MaxWidthLimitedView style={containerStyles} limitedWidth={this.getLimitedWidth()}>
                {backgroundView}
                {this.getNavigationBar()}
                {this.renderMainView()}
            </MaxWidthLimitedView>
        );
    }

    render() {
        return (
            <View style={AppStyles.flexC_stretch}>
                    <WebTopBarComponent
                        activeTab={this.webTabIndex}
                        onTabChosen={(activeTab) => {
                            this.webTabIndex = activeTab;
                            this.forceUpdate();
                        }}
                        navigation={this.props.navigation}
                        route={this.props.route}
                    />
                    {this.renderMaxWidthView()}
                    {<WebBottomBarComponent navigation={this.props.navigation} />}
                {this.getBannerView(56)}
            </View>
        );
    }
}

export default BaseScreen;
