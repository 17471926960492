import React from 'react';
import {View, StyleSheet} from 'react-native';

import PropTypes from 'prop-types';

function DividerLine({color, style, hidden}: any) {
    if (hidden) {
        return null;
    }
    return <View style={[styles.divider, {borderBottomColor: color}, style]} />;
}

DividerLine.defaultProps = {
    color: 'rgba(0,0,0,0.15)',
};

DividerLine.propTypes = {
    color: PropTypes.string,
    style: PropTypes.any,
    hidden: PropTypes.bool
};

const styles = StyleSheet.create({
    divider: {
        borderBottomWidth: StyleSheet.hairlineWidth,
    },
});

export default DividerLine;
