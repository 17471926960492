import {BaseComponent} from '../Base/baseComponent';
import React from 'react';
import FormItemContainer from '../FormItem/FormItemContainer';
import AppColor from '../../classes/appColor';
import {View} from "react-native";

interface CFormProps {
    onAllFieldsValidated?: (valid: boolean) => void;
    validateField?: (field: string, value: any) => Promise<string | undefined>;
    fieldsValue: any;
    style?: any;
    children?: any;
}

class CForm extends BaseComponent<CFormProps> {
    _refs: any[] = [];
    state: {[id: string]: any} = {
        errors: [],
        fields: {},
    };

    constructor(props: CFormProps) {
        super(props);
        this.state.fields = this.props.fieldsValue;
    }

    onSubmitEditing(index: number) {
        console.log('onSubmitEditing: ', index);
        if (index < this._refs.length - 1) {
            const item = this._refs[index + 1]?.current;
            if (item && typeof item.focus === 'function') {
                item.focus();
            }
        }
    }

    async onTextChanged(item: any, index: number, value: any) {
        this.setFieldValue(item, value);
        if (this.state.errors[index]) {
            this.state.errors[index] = undefined;
        }
        item.props.onChangeText?.(value);
        this.setState(this.state);

        const valid = await this.validateAllFields().catch((error) => {
            console.log('validate error: ', error);
        })
        this.props.onAllFieldsValidated?.(Boolean(valid));
    }

    async onItemBlur(item: any, index: number) {
        const error = await this.validateField(item, index);
        if (error) {
            this.state.errors[index] = error;
        }
        const valid = await this.validateAllFields();
        this.props.onAllFieldsValidated?.(valid);
    }

    async validateAllFields(): Promise<boolean> {
        const children = this.getChildrenArray();
        let index = 0;
        for (const item of children) {
            const result = await this.validateField(item, index);
            if (result === '' || result) {
                return false;
            }
            index++;
        }
        return true;
    }

    getFieldValue(item: any) {
        return this.state.fields[item.props.field] || '';
    }

    setFieldValue(item: any, value: any) {
        this.state.fields[item.props.field] = value;
    }

    async validateField(item: any, _index: number): Promise<string | undefined> {
        const validate = this.props.validateField;
        if (validate) {
            const value = this.getFieldValue(item);
            const result = await validate(item.props.field, value);
            return result;
        }
        return undefined;
    }

    getChildrenArray(): any[] {
        const children: any = this.props.children;
        if (Array.isArray(children)) {
            return children;
        }
        return [children];
    }

    getChildrenView() {
        const children = this.getChildrenArray();

        return children.map((item: any, index: number) => {
            const winRef = React.createRef();
            this._refs[index] = winRef;
            let attr: any = {
                ref: winRef,
                clearButtonMode: 'while-editing',
                testID: 'input_' + item.props.field,
                value: this.getFieldValue(item),
                onChangeText: (value: any) => this.onTextChanged(item, index, value),
                onSubmitEditing: () => this.onSubmitEditing(index),
                onBlur: () => {
                    this.onItemBlur(item, index)
                        .then(() => {})
                        .catch((error) => {
                            console.log('CForm Item onBlur Error: ', error);
                        });
                    item.props.onBlur?.();
                },
            };
            if (item.props.numberOfLines > 0) {
                attr.style = this.getMultilineBoxStyle(item.props.numberOfLines);
            }
            const newItem = React.cloneElement(item, attr);
            return (
                <FormItemContainer
                    item={newItem}
                    key={index}
                    style={item.props.style}
                    label={item.props.label}
                    error={item.props.error || this.state.errors[index]}
                />
            );
        });
    }

    getMultilineBoxStyle(line: number) {
        return {
            height: line * 44,
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: AppColor.lightGray,
        };
    }

    render() {
        const children: any = this.props.children;
        if (children.length == 0) return null;

        return <View style={this.props.style}>{this.getChildrenView()}</View>;
    }
}

export default CForm;
