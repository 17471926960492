import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 16,
        alignItems: 'stretch',
    },
    text: {
        fontSize: 16,
        marginBottom: 20
    },
});

export default styles;
