import React from 'react';
import AppStyles from '../appStyles'

import BaseScreen from '../Base/baseScreen';
import { Text, View } from 'react-native';
import CTouchable from '../../components/Button/CTouchable';
import { styles } from './bookListComponent_styles';
import { BookIconComponent } from './bookIconComponent';
import HideableView from '../../components/View/HideableView';
import CEditableRow from '../../components/GridView/CEditableRow';
import { BookItem, BookList } from './invitation'

interface BookListComponentProps {
    parentScreen: BaseScreen;
    bookList: BookList;
    onBookListEdited?: ()=>void;
    editable?: boolean;
    canRepost?: boolean;
}

export default function BookListComponent(props: BookListComponentProps) {

    const onPlayClick = (book: BookItem) => {
        const playMenuId = book.playMenuId;
        props.parentScreen.navigate('play', {playMenuId});
    }

    return <View>
        <HideableView hidden={props.bookList.items.length <= 1}>
                <Text style={styles.title}>{props.bookList.name}</Text>
        </HideableView>
        <View style={styles.bookList}>
            <CEditableRow
                contentStyle={AppStyles.flex1}
                dataset={props.bookList.items}
                getItemView={(book, index) => <CTouchable
                    style={[styles.bookRow, index != props.bookList.items.length - 1 ? styles.rowLine : null]}
                    onPress={() => onPlayClick(book)}>
                    <BookIconComponent icon={book.icon}/>
                    <Text style={styles.bookName}>{book.name}</Text>
                </CTouchable>}/>
        </View>
    </View>
}
