import FetchBlobModule from './fetchBlobModule';
import UserModule from './userModule';
import {AppInstance} from '../app';
import {LoginManager} from 'react-native-fbsdk';
import {ShortUrl, ShortUrlID} from '../classes/shortUrl';
import UrlModule from './urlModule';
import Utils from "../utils/utils";
import { UrlServer } from './UrlServer';

export enum BlobTYPE {
    image = 'image',
    video = 'video',
    music = 'music',
    voice = 'voice',
    movie = 'movie',
    avatar = 'avatar',
    unknown = '',
}

export type OnProgressCallback = (status: OnProgressStatus, progress: number) => void;
export enum OnProgressStatus {
    progress = 'progress',
    done = 'done',
    error = 'error',
}

export class ServerModuleClass {

    constructor() {
    }

    private createHeaders(isStream: Boolean): any {
        const type = isStream ? 'application/octet-stream' : 'application/json';

        return new Headers({
            Accept: 'application/json',
            'Content-Type': type,
            authorization: this.getBearerToken(),
        });
    }

    public getBearerToken(): string {
        return 'Bearer ' + UserModule.authToken;
    }

    public apiCall(url: string, method: string, json?: any): Promise<any> {
        const fullUrl = UrlServer + '/' + url;
        return fetch(fullUrl, {
            method,
            headers: this.createHeaders(false),
            body: JSON.stringify(json),
        })
            .then((response) => {
                console.log('response: ', url, ' ', response.status);
                if (response.status == 200 || response.status == 201)
                    return response.json();
                console.log('apiCall failed: ' + JSON.stringify(response.status) + ' url: ' + url);
                return response ? {error: response} : undefined;
            })
            .catch((error) => {
                console.log('apiCall error: ', fullUrl, error);
                return {error: error};
            });
    }

    public login(email: string, password: string, invitation?: string): Promise<boolean> {
        const data = {
            email: email,
            password: password,
            invitation: invitation,
            deviceId: UserModule.deviceId,
        };

        return this.apiCall('user/login', 'POST', data).then((json) => {
            console.log(json);
            if (json && !json.error) {
                UserModule.updateMySelf(json);
                return true;
            }
            return false;
        });
    }

    public changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };
        return this.apiCall('user/changePassword', 'POST', data).then((json) => {
            console.log(json);
            if (json && !json.error) {
                return true;
            }
            return false;
        });
    }

    public async postUserInfo(info: any) {
        await ServerModule.apiCall('user/info', 'POST', info);
        console.log('postInfo: ', info);
        UserModule.updateMySelf(info);
    }

    public async logout() {
        await this.apiCall('user/logout', 'GET');
        await UserModule.logout();
        LoginManager.logOut();
        AppInstance.updateState(false);
    }

    public uploadPhoto(file: any, folder?: string): Promise<any> {
        const url = UrlServer + '/blob/photo/' + (folder ? folder : '');
        if (typeof file === 'string') {
            file = file.replace('file://', '');
        }
        const fullUrl = url.startsWith('http') ? url : UrlServer + url;
        const header = {
            Authorization: this.getBearerToken(),
            'Content-Type': 'application/octet-stream',
        };
        return FetchBlobModule.uploadBlob(fullUrl, header, file);
    }

    public uploadBase64Image(base64: string) {
        const fullUrl = UrlServer + '/blob/photo/' + 'avatar';
        const image = 'data:image/jpeg;base64,' + base64;

        const header = {
            Authorization: this.getBearerToken(),
            'Content-Type': 'application/octet-stream',
        };

        fetch(fullUrl, {
            method: 'POST',
            headers: header,
            body: image,
        }).catch((error) => {
            console.warn(error);
        });
    }

    public async uploadBlob(shortUrl: ShortUrl, type?: BlobTYPE, onProgress?: OnProgressCallback ): Promise<string | undefined> {
        let url;
        if (shortUrl.startsWith(ShortUrlID)) {
            url = 'blob/' + shortUrl;
        } else {
            url = 'blob/media/' + ( type ?? BlobTYPE.image ) + '/' + Utils.getUUID();
        }
        let json = await ServerModule.apiCall(url, 'POST');
        if (!json) {
            console.log('failed to uploadBlob: ', url);
            return undefined;
        }
        const localUrl = UrlModule.getLocalUri(shortUrl);
        if (json.url && localUrl) {
            const blobType = UrlModule.getBlobType(shortUrl);
            const result = await ServerModule.uploadBlobToS3(localUrl, json.url, blobType, onProgress).catch((err) => {
                console.log('uploadBlobToS3 error: ', err);
            });
            console.log('Uploaded blob: ', result, shortUrl);
            return result ? UrlModule.getServerUrl(shortUrl) : undefined;
        }
        return undefined;
    }

    public uploadBlobToS3(
        localUri: string,
        s3Url: string,
        blobType: BlobTYPE | undefined,
        onProgress?: OnProgressCallback,
    ): Promise<any> {
        return new Promise((resolve) => {
            console.log('try uploadBlobToS3: ', localUri, s3Url);
            let type =
                blobType == BlobTYPE.video || blobType == BlobTYPE.movie
                    ? `video/mp4`
                    : blobType == BlobTYPE.image
                    ? `image/jpeg`
                    : 'mp3';
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', s3Url);
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable && onProgress) {
                    onProgress(OnProgressStatus.progress, event.loaded / event.total);
                }
            };
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('file successfully uploaded to S3: ', localUri);
                        resolve(true);
                        return;
                    } else {
                        console.log('Error when uploadToS3: ', xhr.responseText);
                        resolve(false);
                    }
                }
            };
            xhr.setRequestHeader('Content-Type', type);
            xhr.send({uri: localUri, type: type});
        });
    }

    public async getVideoURL(videoId: number) {
        let json = await ServerModule.apiCall('blob/video/' + videoId, 'GET');
        console.log('get videoURL: ', videoId, ' URL is: ', json.getURL);
        return json.getURL;
    }

    // public async uploadVideo(uri: string, params?: any, onProgress?: OnProgressCallback) {
    //     let json = await this.apiCall('blob/video/upload', 'POST', params);
    //     if (!json) {
    //         return undefined;
    //     }
    //     let videoId = json.videoId;
    //     if (json.putURL) {
    //         const result = await this.uploadBlobToS3(Utils.getFileUri(uri), json.putURL, BlobTYPE.video, onProgress);
    //         if (!result) {
    //             videoId = undefined;
    //         }
    //     }
    //     console.log('Uploaded Video with Id: ', videoId);
    //     return videoId;
    // }

    public async deleteVideo(videoId: number) {
        if (videoId) {
            return await this.apiCall('blob/video/' + videoId, 'DELETE');
        }
    }

    public async uploadThumbnail(localUri: any, id: string) {
        let {url} = await this.apiCall('blob/folder/thumbnail/' + id, 'POST');
        if (!url) {
            return;
        }
        return await this.uploadBlobToS3(localUri, url, BlobTYPE.image);
    }

    public getAuthorizedHeader() {
        return {
            authorization: this.getBearerToken(),
        };
    }
}

const ServerModule = new ServerModuleClass();
export default ServerModule;
