import React from 'react';
import {Modal} from 'react-native';
import PlatformModule from '../../modules/PlatformModule';
import WebModal from 'react-modal';

interface CModalProps {
    visible: boolean;
    onRequestClose?: () => void;
    supportedOrientations?: any;
    transparent?: boolean;
    onDismiss?: () => void;
    children?: React.ReactNode;
}

class CModal extends React.PureComponent<CModalProps> {
    render() {
        if (PlatformModule.isWeb()) {
            const backgroundColor = this.props.transparent ? '#00000000' : '#88888888';

            const style = {
                content: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: backgroundColor,
                },
                overlay: {
                    backgroundColor: backgroundColor,
                },
            };

            WebModal.setAppElement('#root');
            return (
                <WebModal
                    isOpen={this.props.visible}
                    onAfterOpen={() => {}}
                    onRequestClose={this.props.onRequestClose}
                    style={style}
                    contentLabel="WebPopup"
                >
                    {this.props.children}
                </WebModal>
            );
        }

        return (
            <Modal
                visible={this.props.visible}
                transparent={this.props.transparent}
                onDismiss={this.props.onDismiss}
                onRequestClose={this.props.onRequestClose}
                presentationStyle={'overFullScreen'}
                supportedOrientations={this.props.supportedOrientations}
            >
                {this.props.children}
            </Modal>
        );
    }
}

export default CModal;
