import React from 'react';
import {Image, View, Text} from 'react-native';
import { DeviceScreenSize } from '../../classes/appContants'
import styles from './welcome_styles';
import AppStyles, {AppScreenLayout} from '../appStyles';
import Carousel from 'react-native-snap-carousel';
import BaseScreen from '../Base/baseScreen';
import {WebBigScreenWidthLimited} from '../../components/View/MaxWidthLimitedView';
import SwitchView from '../../components/View/SwitchView';
import TitleText from '../../components/Text/TitleText';
import ClickableText from '../../components/Text/ClickableText';
import {LocalesModule} from '../../modules/LocalesModule';
import AppstoreComponent from "./appstoreComponent";

export default class WelcomeScreen extends BaseScreen {
    slides = [
        {
            image: require('../../Images/book.jpg'),
            title: LocalesModule.index.slide_promotion1,
        },
        {
            image: require('../../Images/speaking.jpg'),
            title: LocalesModule.index.slide_promotion2,
        },
        {
            image: require('../../Images/mobile.jpg'),
            title: LocalesModule.index.slide_promotion3,
        },
    ];

    constructor(props: any) {
        super(props);
        this.hideNavigationBar = true;
    }

    onFocus() {
        super.onFocus();
        this.webTabIndex = this.props.route.params?.activeTab ?? 0;
    }

    renderItem = ({item}: any) => {
        return (
            <View style={AppStyles.flexC_center}>
                <Image source={item.image} style={ { width: 400, height: 400 } } />
                <Text style={styles.imageText}> {item.title} </Text>
            </View>
        );
    };

    getCarouselView() {
        let sliderWidth =
            Math.min(DeviceScreenSize.width, WebBigScreenWidthLimited) - AppScreenLayout.marginHorizontal * 2;
        return (
            <View>
            <Carousel
                data={this.slides}
                renderItem={this.renderItem}
                sliderWidth={sliderWidth}
                itemWidth={sliderWidth}
                autoplay={true}
                enableSnap={true}
                loop={true}
                autoplayInterval={3000}
            />
            <View style={styles.middleContainer}>
                <AppstoreComponent/>
                <Text style={styles.downloadDesc}> {LocalesModule.index.middle_description_1} <Text
                    style={AppStyles.fweight_bold}> {LocalesModule.product_name} </Text> {LocalesModule.index.middle_description_2}
                </Text>
            </View>
            </View>
        );
    }

    getCooperationView() {
        return (
            <View style={AppStyles.flexC_center}>
                <TitleText text={LocalesModule.top_bar.contact_us} />
                <Text style={AppStyles.fsize20}> {LocalesModule.index.cooperation_info}</Text>
            </View>
        );
    }

    getPublishView() {
        return (
            <View style={AppStyles.flexC_center}>
                <TitleText text={LocalesModule.index.writer_title} />
                <Text style={AppStyles.fsize20}>
                    {LocalesModule.index.writer_info}
                    <ClickableText
                        style={AppStyles.link}
                        testID={'publishProceed'}
                        title={LocalesModule.index.publish_story_proceed}
                    />
                </Text>
            </View>
        );
    }

    getMainView() {
        return (
            <View style={styles.container}>
                <View style={AppStyles.mg40}>
                    <SwitchView activeIndex={this.webTabIndex}>
                        {this.getCarouselView()}
                        {this.getPublishView()}
                        {this.getCooperationView()}
                    </SwitchView>
                </View>
            </View>
        );
    }
}
