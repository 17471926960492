import { Language } from '../classes/user'
import ServerModule from './serverModule';
import { AppInstance } from '../app';
import { LocalesModule } from './LocalesModule';
import Utils from '../utils/utils';

export class SignupModuleClass {

    fields: any = {
        learningLanguages : [],
    };

    constructor() {}

    init() {
        this.fields = {};
    }

    doSignup() {
        return ServerModule.apiCall('user/signup', 'POST', {
            ...this.fields,
            invitation: AppInstance.state.initUrl,
            password: this.fields.password2,
        });
    }

    async validateField(field: string, value: string): Promise<string | undefined> {
        if (field && (!value || !value.length)) {
            return '';
        }
        switch (field) {
            case 'name':
                if (value.length < 2) {
                    return LocalesModule.signup.warning.name_characters;
                }
                break;
            case 'email':
                if (!Utils.validateEmail(value)) {
                    return LocalesModule.signup.warning.invalid_email;
                }
                const result = await ServerModule.apiCall('user/testEmail', 'POST', {email: value});
                if (result.error && !result.result) {
                    return LocalesModule.signup.warning.network_problem;
                }
                if (!result.result) {
                    return LocalesModule.signup.warning.email_registered;
                }
                break;
            case 'password1':
                if (value.length < 6) {
                    return LocalesModule.signup.warning.invalid_password;
                }
                break;
            case 'password2':
                if (!value || value.length < 6) {
                    return LocalesModule.signup.warning.invalid_password;
                }
                if (!value || value !== this.fields.password1) {
                    return LocalesModule.signup.warning.password_different;
                }
                break;
        }
        return undefined;
    }

    onLevelSelected(level: number) {
        this.fields.level = level;
        console.log('level: ', level);
    }

    onLanguageSelected(language: Language) {
        this.fields.language = language;
        console.log('onLanguageSelected: ', language);
        if (language == Language.english) {
            this.fields.learningLanguages = [Language.chinese];
        } else {
            this.fields.learningLanguages = [Language.english];
        }
    }

    onLearningLanguagesSelected(languages: Language) {
        this.fields.learningLanguages = languages;
    }
}

const SignupModule = new SignupModuleClass();
export default SignupModule;
