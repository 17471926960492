import React from 'react';

import {View, Image, Linking} from 'react-native';
import {BaseComponent} from '../../components/Base/baseComponent';
import CTouchable from "../../components/Button/CTouchable";

class AppstoreComponent extends BaseComponent<any> {
    constructor(props: any) {
        super(props);
    }

    gotoApp(isGoogle: Boolean) {
        const url = isGoogle?  "https://play.google.com/store/apps/details?id=com.chatskyclient" : "https://apps.apple.com/app/id1548083217";
        Linking.openURL(url ).then(()=>{});
    }

    render() {
        return (
            <View style={this.props.style}>
                <CTouchable onPress={ ()=>this.gotoApp(false) } >
                    <Image
                        style={{
                            borderRadius: 5,
                            width: 120,
                            height: 30,
                            marginBottom: 5,
                        }}
                        source={require('../../Images/app_store.png')}
                    />
                </CTouchable>
                <CTouchable onPress={ ()=>this.gotoApp(true) } >
                    <Image
                        style={{borderRadius: 5, width: 120, height: 30}}
                        source={require('../../Images/google_play.png')}
                    />
                </CTouchable>
            </View>
        );
    }
}

export default AppstoreComponent;
