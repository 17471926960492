import { Component } from 'react';
import { Emitter } from '../../utils/emitter';
import { Subscription } from 'rxjs';

export class BaseComponent<P = {}, S = {}> extends Component<P, S> {
    private _emitters: any[] = [];
    private _subscriptions: Subscription[] = [];
    public _isMounted = false;
    public _name?: string;
    public _shouldUpdate = false;

    addListener(emitter: Emitter, event: string, listener: (...args: any[]) => any) {
        this._emitters.push({
            emitter: emitter,
            event: event,
            listener: listener,
        });
        emitter.addListener(event, listener);
    }

    addSubscription(sub: Subscription) {
        this._subscriptions.push(sub);
    }

    refresh() {
        this._shouldUpdate = true;
        this.setState(this.state);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    navigate(screen?: string, params?: any) {
        const navigation: any = this.props && (this.props as any).navigation;
        navigation?.navigate(screen, params);
    }

    setState(state: any) {
        if (this._isMounted) {
            super.setState(state);
        }
    }

    forceUpdate() {
        if (this._isMounted) {
            super.forceUpdate();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
        for (const emitter of this._emitters) {
            emitter.emitter.removeListener(emitter.event, emitter.listener);
        }
    }
}
