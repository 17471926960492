import { BlobTYPE } from './serverModule';
//import FileSystemModule from './FileSystemModule';
import {ShortUrl, ShortUrlID} from '../classes/shortUrl';
//import Utils from '../utils/utils';
import { UrlServer, UrlWeb } from './UrlServer';
import UserModule from './userModule';
import CryptService from '../utils/cryptService';

class UrlModuleClass {
    initLocal(url: string, blobType?: BlobTYPE): ShortUrl | undefined {
        let sUrl;
        if (url.startsWith(ShortUrlID)) {
            sUrl = url;
        } else {
            if (blobType != undefined) {
                sUrl = ShortUrlID + blobType + '/' + url;
            } else {
                sUrl = this.stripShortUrl(url);
            }
        }
        return sUrl;
    }

    // async moveFromFile(url: string, blobType: BlobTYPE) {
    //     const uuid = Utils.getUUID();
    //     const shortUrl = this.initLocal(uuid, blobType);
    //     if (shortUrl) {
    //         await FileSystemModule.moveFile(url, this.getLocalUrl(shortUrl), false);
    //     }
    //     return shortUrl;
    // }

    init(url: string | ShortUrl | undefined, blobType?: BlobTYPE): ShortUrl | undefined {
        if (!url) {
            return undefined;
        }
        let sUrl = url;
        if (!url.startsWith(ShortUrlID) && blobType != undefined) {
            if(url.toLowerCase().startsWith('http')) {
                sUrl = url;
            } else {
                sUrl = ShortUrlID + blobType + '/' + url;
            }
        }
        return sUrl;
    }

    // async initFolder(blobType: BlobTYPE) {
    //     const folder = ShortUrlID + '/' + blobType;
    //     await FileSystemModule.makeDir(folder);
    // }

    getServerUrl(shortUrl: ShortUrl): string {
        if (shortUrl.startsWith('http')) {
            return shortUrl;
        }
        return UrlServer + '/blob/' + shortUrl;
    }

    private stripShortUrl(url: string | undefined) {
        if (url) {
            // const documentFolder = FileSystemModule.getDocumentFolder() + '/';
            // const index = url.lastIndexOf(documentFolder);
            // let subUrl = url;
            // if (index > 0) {
            //     subUrl = url.substring(index + documentFolder.length);
            //     return Utils.getFileNameWithoutExt(subUrl);
            // }
            return url;
        }
        return '';
    }

    getBlobType(shortUrl: ShortUrl): BlobTYPE {
        if (shortUrl.startsWith(ShortUrlID)) {
            const sub = shortUrl.substring(ShortUrlID.length);
            const index = sub.indexOf('/');
            return sub.substring(0, index) as BlobTYPE;
        }
        return BlobTYPE.unknown;
    }

    getBlobId(shortUrl: ShortUrl): string {
        const index = shortUrl.lastIndexOf('/');
        return shortUrl.substring(index + 1);
    }

    getFileExt(blobType: BlobTYPE) {
        switch (blobType) {
            case BlobTYPE.video:
            case BlobTYPE.movie:
                return '.mp4';
            case BlobTYPE.voice:
                return '.m4a';
            case BlobTYPE.music:
                return '.mp3';
            case BlobTYPE.image:
            case BlobTYPE.avatar:
                return '.png';
        }
        return '';
    }

    getLocalUrl(shortUrl: ShortUrl): string {
        // if (shortUrl.startsWith(ShortUrlID)) {
        //     const fileExt = this.getFileExt(this.getBlobType(shortUrl));
        //     return FileSystemModule.getFilePath(shortUrl + fileExt);
        // }
        return shortUrl;
    }

    getLocalUri(shortUrl: ShortUrl): string {
        // if (shortUrl.startsWith(ShortUrlID)) {
        //     const fileExt = this.getFileExt(this.getBlobType(shortUrl));
        //     return FileSystemModule.getFileUri(shortUrl + fileExt);
        // }
        return shortUrl;
    }

    // async localFileExists(shortUrl: ShortUrl): Promise<boolean> {
    //     return await FileSystemModule.fileExists(this.getLocalUrl(shortUrl));
    // }

    showExists(shortUrl?: ShortUrl) {
        if (!shortUrl) {
            console.log('Short URL is empty');
            return;
        }
        // this.localFileExists(shortUrl).then((result) => {
        //     console.log('Short URL exists: ', result, shortUrl);
        // });
    }

    makeRefLink(routeName: string, params: string) {
        const json = {name: UserModule.myself.name, userId: UserModule.myself.id};
        const ref = CryptService.encodeHex(JSON.stringify(json));
        let shareLink = UrlWeb + '/' + routeName + '?&ref=' + ref + '&' + params;
        shareLink = encodeURI(shareLink);
        return shareLink;
    }
}

const UrlModule = new UrlModuleClass();
export default UrlModule;
