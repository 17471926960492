import AsyncStorage from '@react-native-async-storage/async-storage';
import PlatformModule from './PlatformModule';
import {User} from '../classes/user';
import {LocalesModule} from './LocalesModule';

export class UserModuleClass {
    public deviceId = '';
    public authToken = '';
    public myself!: User;

    constructor() {}

    public async loadMyself(): Promise<User> {
        const isEmulator = true; // await DeviceInfo.isEmulator();
        if (isEmulator && !this.deviceId) {
            console.log('Is Emulator.');
            this.deviceId = 'dummy';
        }

        const authToken = await AsyncStorage.getItem('authToken');
        if (authToken) {
            this.authToken = authToken;
        }

        const deviceId = await AsyncStorage.getItem('deviceId');
        if (deviceId && !this.deviceId) {
            this.deviceId = deviceId;
        }

        if (!this.deviceId && PlatformModule.isWeb()) {
            this.deviceId = 'web';
        }

        const myself = await AsyncStorage.getItem('myself');
        this.myself = new User(myself && JSON.parse(myself));

        console.log('LoadMyself, deviceId: ', this.deviceId);

        return this.myself;
    }

    public isActivated() {
        return this.myself.status === 1;
    }

    public isInfoEnough() {
        return !!this.myself.name && !!this.myself.description && !!this.myself.avatar;
    }

    public getLanguageLevelString(level: number) {
        const levelStrings = LocalesModule.signup.levels;
        return levelStrings[level];
    }

    public updateMySelf(json: any) {
        if (!this.myself || (json.id && json.id != this.myself.id)) {
            this.myself = new User();
        }
        Object.assign(this.myself, json);

        if (json.token) {
            this.authToken = json.token;
        }
        this.save().then(()=>{});
    }

    public async logout() {
        await AsyncStorage.removeItem('authToken');
        await AsyncStorage.removeItem('myself');
        await AsyncStorage.removeItem('deviceId');
    }

    public setDeviceId(deviceId: string) {
        this.deviceId = deviceId;
    }

    public getGenderIcon(isFemale: boolean) {
        return isFemale ? './Ionicons/md-female' : './Ionicons/md-male';
    }

    public async save() {
        await AsyncStorage.setItem('deviceId', this.deviceId);
        await AsyncStorage.setItem('myself', JSON.stringify(this.myself));
        await AsyncStorage.setItem('authToken', this.authToken);
    }
}

const UserModule = new UserModuleClass();
export default UserModule;
