/* eslint-disable new-cap */
import React from 'react';
import {
  TouchableOpacity,
  Platform,
  View,
  TouchableNativeFeedback,
  StyleSheet,
  Text
} from 'react-native';

import variable from './variables/platform';
import { PLATFORM } from './variables/commonColor';

interface BaseButtonProps {
  style?: any,
  onPress: ()=>void,
  testID?: string,
  buttonUppercaseAndroidText?: boolean,
  block?: boolean,
  primary?: boolean,
  transparent?: boolean,
  success?: boolean,
  danger?: boolean,
  warning?: boolean,
  info?: boolean,
  bordered?: boolean,
  disabled?: boolean,
  rounded?: boolean,
  large?: boolean,
  small?: boolean,
  active?: boolean,
  androidRippleColor?: string,
  activeOpacity?: number,
  children?: any
};

export class BaseButton extends React.PureComponent<BaseButtonProps> {

  context : any = {
  };
  _root: any;

  setRoot(c: any) {
    this._root = c;
  }

  getInitialStyle() {
    return {
      borderedBtn: {
        borderWidth: this.props.bordered
          ? variable.buttonDefaultBorderWidth
          : undefined,
        borderRadius:
          this.props.rounded && this.props.bordered
            ? variable.borderRadiusLarge
            : variable.buttonDefaultBorderRadius
      }
    };
  }

  prepareRootProps() {
    const {style, disabled, ...others} = this.props;
    return {
      style: StyleSheet.flatten(StyleSheet.compose(this.getInitialStyle().borderedBtn, style)),
      ...others
    }
  }

  render() {
    const variables = this.context.theme
      ? this.context.theme['@@shoutem.theme/themeStyle'].variables
      : variable;

    const children =
      Platform.OS === PLATFORM.IOS || !variables.buttonUppercaseAndroidText
        ? this.props.children
        : React.Children.map(this.props.children, child =>
            child && child.type === Text
              ? React.cloneElement(child as any, {
                uppercase: this.props.buttonUppercaseAndroidText === false
                ? false : variables.buttonUppercaseAndroidText,
                ...child.props
              })
              : child
          );

    const rootProps = this.prepareRootProps();

    if (
      Platform.OS === PLATFORM.IOS ||
      Platform.OS === PLATFORM.WEB ||
      variables.androidRipple === false ||
      Platform.Version < 21
    ) {
      return (
        <TouchableOpacity
          {...rootProps}
          ref={this.setRoot.bind(this)}
          activeOpacity={
            this.props.activeOpacity && this.props.activeOpacity > 0
              ? this.props.activeOpacity
              : variable.buttonDefaultActiveOpacity
          }
        >
          {children}
        </TouchableOpacity>
      );
    }
    if (this.props.rounded) {
      const buttonStyle = { ...rootProps.style };
      const buttonFlex =
        this.props.block
          ? variable.buttonDefaultFlex
          : buttonStyle.flex;
      return (
        <View
          style={[
            { maxHeight: buttonStyle.height },
            buttonStyle,
            { paddingTop: undefined, paddingBottom: undefined }
          ]}
        >
          <TouchableNativeFeedback
            ref={this.setRoot.bind(this)}
            background={TouchableNativeFeedback.Ripple(
              this.props.androidRippleColor || variables.androidRippleColor,
              true
            )}
            {...rootProps}
          >
            <View
              style={[
                // eslint-disable-next-line no-use-before-define
                styles.childContainer,
                {
                  paddingTop: buttonStyle.paddingTop,
                  paddingBottom: buttonStyle.paddingBottom,
                  height: buttonStyle.height,
                  flexGrow: buttonFlex
                }
              ]}
            >
              {children}
            </View>
          </TouchableNativeFeedback>
        </View>
      );
    }
    return (
      <TouchableNativeFeedback
        ref={this.setRoot.bind(this)}
        background={
          this.props.transparent
            ? TouchableNativeFeedback.Ripple('transparent', false)
            : TouchableNativeFeedback.Ripple(
                variables.androidRippleColor,
                false
              )
        }
        {...rootProps}
      >
        <View {...rootProps}>{children}</View>
      </TouchableNativeFeedback>
    );
  }
}

const styles = StyleSheet.create({
  childContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
