import { LocalesModule } from '../modules/LocalesModule';

export const ONE_DAY_MM_SEC = 1000 * 3600 * 24;

class DateTimeUtils {

    static getElapseTime(time: number): string {
        if (!time) return '';

        const delay = (Date.now() - time) / (1000 * 60);
        if (delay < 1) return LocalesModule.common.now;
        if (delay < 10) return '' + delay.toFixed(2) + LocalesModule.home.min_earlier;
        const t = new Date(time);
        return t.getHours() + ':' + t.getMinutes();
    }

    static to2DigitNumber(n: number) {
        return n > 9 ? '' + n : '0' + n;
    }

    static to3DigitNumber(n: number) {
        return n.toString().padStart(3, '0');
    }

    static getTimeString(time: number) {
        let left = time;
        let formattedHours = '';
        if (time > 3600) {
            const hour = Math.floor(time / 3600);
            formattedHours = this.to2DigitNumber(hour);
            left = time - hour * 3600;
        }
        const formattedMinutes = this.to2DigitNumber(Math.floor(left / 60));
        const formattedSeconds = this.to2DigitNumber(Math.floor(left % 60));
        const ms = `${formattedMinutes}:${formattedSeconds}`;
        return formattedHours ? `${formattedHours}:${ms}` : ms;
    }

    static formatDate(date: Date, withMMSecond?: boolean) {
        return (
            [
                date.getFullYear(),
                this.to2DigitNumber(date.getMonth() + 1),
                this.to2DigitNumber(date.getDate()),
            ].join('-') +
            ' ' +
            [
                this.to2DigitNumber(date.getHours()),
                this.to2DigitNumber(date.getMinutes()),
                this.to2DigitNumber(date.getSeconds()),
            ].join(':')
            + ( withMMSecond ? '.' + this.to3DigitNumber(date.getMilliseconds()) : '' )
        );
    }

    static getDateString(daysAfter: number): [number, string] {
        let date = new Date(new Date().setDate(new Date().getDate() + daysAfter));
        return [date.getFullYear(), LocalesModule.weekdayNames[date.getDay()] + ', ' + LocalesModule.monthNames[date.getMonth()] + ' ' + date.getDate()];
    }

    static getToday() {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        //return new Date(date.getTime() + 8 * ONE_DAY_MM_SEC);
        return date;
    }

    static getDateTimeByOffNowDays( days: number ) : number {
        const today = this.getToday();
        return new Date(today.getTime() + days * ONE_DAY_MM_SEC).getTime();
    }

    static getDaysAfterNow(dateStr: string): number {
        const date = new Date(dateStr);
        date.setHours(0, 0, 0, 0);
        const today = this.getToday();
        return ( date.getTime() - today.getTime() ) / ONE_DAY_MM_SEC;
    }

    static getDaysOffNowByTime(time: number): number {
        const today = this.getToday();
        return ( time - today.getTime() ) / ONE_DAY_MM_SEC;
    }

}
export default DateTimeUtils;
