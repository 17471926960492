import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';
import { DefaultFont } from '../../classes/appContants';

export const styles = StyleSheet.create({
    icon: {
        width: 52,
        height: 52,
        margin: 2,
        marginRight: 4,
        borderRadius: 5,
        color: AppColor.dark_cyan,
    },
    language: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: 14,
        width: 28,
        height: 28,
    },
    item: {
        paddingVertical: 6,
        marginHorizontal: 8,
        alignItems: 'stretch',
        borderBottomWidth: 0.5,
        borderBottomColor: AppColor.lightGray,
    },
    vs: {
        marginHorizontal: 8,
        fontSize: 17,
        color: AppColor.mediumGray,
        fontWeight: 'bold',
        fontFamily: DefaultFont,
    },
    score: {
        marginHorizontal: 8,
        fontSize: 22,
        color: AppColor.mediumGray,
        fontWeight: 'bold',
        fontFamily: DefaultFont,
    },
    time: {
        color: AppColor.mediumGray,
    },
    bookRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    participantsRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginVertical: 2,
    },
    resultRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginVertical: 2,
    },
    titleRow: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    title: {
        flexShrink: 1,
        color: AppColor.darkTextColor,
        fontWeight: 'bold',
        fontSize: 20,
        padding: 3,
    }
});
