import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    errorInfo: {
        marginLeft: 16,
        marginTop: 8,
        color: '#cc0000',
    },
});

export default styles;
