import React, {PureComponent} from 'react';

interface HideableViewProps {
    hidden?: boolean;
    children?: React.ReactNode;
}

export default class HideableView extends PureComponent<HideableViewProps> {
    constructor(props: HideableViewProps) {
        super(props);
    }

    render() {
        if (this.props.hidden) {
            return null;
        }
        return this.props.children;
    }
}
