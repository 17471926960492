import React from 'react';

import {View, Text} from 'react-native';
import {styles} from './webBottomBarComponent_styles';
import {BaseComponent} from '../../components/Base/baseComponent';
import ClickableText from '../../components/Text/ClickableText';
import {LocalesModule} from '../../modules/LocalesModule';
import AppColor from '../../classes/appColor'

interface WebBottomBarComponentProps {
    navigation?: any;
}

class WebBottomBarComponent extends BaseComponent<WebBottomBarComponentProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const styleLink = {
            color: AppColor.headerInActiveTextColor,
                fontSize: 14,
                textDecoration: 'none',
        };

        return (
            <View style={styles.container}>
                <Text style={[styles.boldText]}>{LocalesModule.bottom_bar.promotion}</Text>
                <View style={styles.row}>
                    <View style={styles.cell}>
                        <ClickableText
                            style={styles.normalText}
                            title={LocalesModule.bottom_bar.about + " " + LocalesModule.product_name}
                            testID={'about'}
                            onPress={() => {
                                this.navigate('about');
                            }}
                        />
                    </View>
                    <View style={styles.cell}>
                        <a href="/terms.html"
                           style={ styleLink }>{LocalesModule.bottom_bar.terms_of_use}</a>
                    </View>
                    <View style={styles.cell}>
                        <Text style={[styles.cell, styles.normalText]}>{LocalesModule.bottom_bar.copyright}</Text>
                    </View>
                </View>
            </View>
        );
    }
}

export default WebBottomBarComponent;
