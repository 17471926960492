import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    imageText: {
        fontSize: 36,
        marginTop: 20,
    },
    middleContainer: {
        marginTop: 30,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    downloadDesc: {
        fontSize: 20,
        marginTop: 30,
    }
});

export default styles;
