import React from 'react';
import {View} from 'react-native';
import AppColor from '../../classes/appColor';
import CImage from '../Image/CImage';
import Label from './Label';

interface SectionHeaderProps {
    hidden?: boolean;
    title: string;
    style?: any;
    hideDivider?: boolean;
    rightButtonIcon?: string;
    rightButtonStyle?: any;
    rightButtonPress?: () => void;
}

class SectionHeader extends React.PureComponent<SectionHeaderProps> {
    render() {
        if (this.props.hidden) return null;

        const viewStyle = {
            borderBottomColor: AppColor.lightGray,
            borderBottomWidth: this.props.hideDivider ? 0 : 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const rightButtonStyle = {width: 28, height: 28, fontSize: 28, marginTop: 10};

        return (
            <View style={[viewStyle, this.props.style]}>
                <Label
                    style={{
                        fontSize: 18,
                        marginTop: 20,
                        marginBottom: 10,
                        color: AppColor.gray,
                        fontWeight: 'bold',
                    }}
                >
                    {this.props.title}
                </Label>
                <CImage name={this.props.rightButtonIcon} style={[rightButtonStyle, this.props.rightButtonStyle]}
                        onPress={this.props.rightButtonPress}></CImage>
            </View>
        );
    }
}

export default SectionHeader;
