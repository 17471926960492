import React from 'react';
import {BaseComponent} from '../../components/Base/baseComponent';
import DeletableView from '../View/DeletableView';

interface CEditableRowProps {
    dataset: any[];
    style?: any;
    hidden?: boolean;
    contentStyle?: any;
    isDeleteMode?: boolean;
    deleteIconInCenter?: boolean;
    getItemView: (item: any, index: number) => any;
    onItemDelete?: (item: any, index: number) => void;
}

class CEditableRow extends BaseComponent<CEditableRowProps> {
    constructor(props: any) {
        super(props);
    }

    getItemView(item: any, index: number): any {
        return (
            <DeletableView
                key={index}
                style={this.props.style}
                childrenStyle={this.props.contentStyle}
                center={this.props.deleteIconInCenter}
                isDeleteMode={this.props.isDeleteMode}
                onDelete={() => this.props.onItemDelete && this.props.onItemDelete(item, index)}
            >
                {this.props.getItemView(item, index)}
            </DeletableView>
        );
    }

    render() {
        if (this.props.hidden) {
            return null;
        }
        const dataSet = this.props.dataset || [];
        return dataSet.map((item: any, index: number) => this.getItemView(item, index));
    }
}

export default CEditableRow;
