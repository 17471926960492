import { Platform } from 'react-native';

class PlatformModuleClass {
    isWeb() {
        return Platform.OS == 'web';
    }

    isAndroid() {
        return Platform.OS == 'android';
    }

    isIOS() {
        return Platform.OS == 'ios';
    }

    isIOSWeb() {
        return navigator.userAgent.match(/(iPhone|iPod|iPad);?/i);
    }

    isAndroidWeb() {
        return navigator.userAgent.match(/android/i);
    }

    convertStyle(style: any): any {
        if (this.isWeb() && style.aspectRatio) {
            if (style.width && !style.height) {
                style.height = style.width * style.aspectRatio;
            } else if (!style.width && style.height) {
                style.width = style.height / style.aspectRatio;
            }
        }
        return style;
    }
}

const PlatformModule = new PlatformModuleClass();
export default PlatformModule;
