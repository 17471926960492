import React from 'react';
import {Text, TextProps} from 'react-native';
import {DefaultFont} from '../../classes/appContants';

class Label extends React.Component<TextProps> {
    render() {
        const {style, ...passThroughProps} = this.props;
        const textStyle = {
            fontFamily: DefaultFont,
        };
        return <Text style={[textStyle, style]} {...passThroughProps} />;
    }
}

export default Label;
