import React from 'react';
import {View, Text} from 'react-native';
import BaseScreen from '../Base/baseScreen';
import AppStyles from '../appStyles';
import {LocalesModule} from "../../modules/LocalesModule";

export default class ContactUsScreen extends BaseScreen {
    constructor(props: any) {
        super(props);
        this.title = LocalesModule.top_bar.contact_us;
    }

    getMainView() {
        return (
            <View style={AppStyles.screen}>
                <Text style={AppStyles.mainText}> { LocalesModule.index.cooperation_info } </Text>
            </View>
        );
    }
}
