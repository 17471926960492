import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor'

export const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 30,
        height: 100,
        backgroundColor: AppColor.bottomBarColor,
        justifyContent: 'center',
    },
    row: {
        height: 40,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boldText: {
        color: AppColor.headerInActiveTextColor,
        fontSize: 20,
        textAlign: 'center',
    },
    normalText: {
        color: AppColor.headerInActiveTextColor,
        fontSize: 14,
        textAlign: 'center',
    },
    cell: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    link: {
        color: AppColor.white,
    }
});
