import React from 'react';
import {Text, View} from 'react-native';
import styles from './CTab_styles';
import {BaseComponent} from '../Base/baseComponent';
import CImage from '../Image/CImage';
import CTouchable from '../Button/CTouchable';
import BadgeView from '../View/BadgeView';

export interface TabItemProps {
    labelStyle?: any;
    activeStyle?: any;
    style?: any;
    icon?: string;
    testID?: string;
    label?: string;
    badgeNumber?: number;
    gray?: boolean;
    active: boolean;
    onPress: () => void;
}

export class TabItem extends BaseComponent<TabItemProps> {
    render() {
        const styleLabel = this.props.active ? [this.props.labelStyle, this.props.activeStyle] : this.props.labelStyle;
        return (
            <CTouchable testID={this.props.testID} onPress={this.props.onPress}>
                <View style={[styles.itemContainer, this.props.style]}>
                    <CImage style={styles.icon} name={this.props.icon} />
                    <Text style={styleLabel}>{this.props.label}</Text>
                    <BadgeView size={17} number={this.props.badgeNumber} gray={this.props.gray} />
                </View>
            </CTouchable>
        );
    }
}

export interface CTabItem {
    icon?: string;
    testID?: string;
    label?: string;
    badgeNumber?: number;
    grayBadge?: boolean;
}

export interface CTabProps {
    items: CTabItem[];
    style?: any;
    itemStyle?: any;
    labelStyle?: any;
    activeStyle?: any;
    activeIndex?: number;
    onItemSelected?: (index: number) => void;
}

class CTab extends BaseComponent<CTabProps> {
    render() {
        return (
            <View style={[styles.bar, this.props.style]}>
                {this.props.items.map((it, index) => (
                    <TabItem
                        key={index}
                        testID={it.testID}
                        icon={it.icon}
                        label={it.label}
                        badgeNumber={it.badgeNumber}
                        gray={it.grayBadge}
                        style={this.props.itemStyle}
                        labelStyle={this.props.labelStyle}
                        activeStyle={this.props.activeStyle}
                        active={index === this.props.activeIndex}
                        onPress={() => this.props.onItemSelected && this.props.onItemSelected(index)}
                    />
                ))}
            </View>
        );
    }
}

export default CTab;
