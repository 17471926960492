import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor'

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    containerWeb: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
        minHeight: 720,
    },
    mainView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    centerInScreen: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: AppColor.backgroundColor,
    },
    titleBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    title: {
        fontSize: 24,
        fontWeight: '500',
        margin: 6,
        color: AppColor.darkPrimaryColor,
    },
    titleIcon: {
        resizeMode: 'cover',
        width: 36,
        height: 36,
        borderRadius: 18,
    },
});

export default styles;
