export const AppColor = {
    backgroundColor: '#FFFFFF',
    navigationBarColor: '#612DA8',
    bottomBarColor: '#612DA8',
    headerColor: '#fff0e8',
    sectionHeaderColor: '#fff0e8',
    headerTextColor: '#251A68',
    headerLightTextColor: '#917AC0',
    headerActiveTextColor: '#DF8800',
    headerInActiveTextColor: '#ffffffcc',
    primaryColor: '#FF9800',
    darkPrimaryColor: '#512DA8',
    lightPrimaryColor: '#D1C4E9',
    darkTextColor: '#555555',
    red: '#ff0000',
    almost_red: '#e71d36',
    blue: '#90caf9',
    light_blue: '#f5fdff',
    dark_blue: '#5d99c6',
    light_pink: '#fff0e8',
    pink: '#fedbd0',
    dark_pink: '#caa99f',
    light_green: '#C5E5A5',
    green: '#10EF10',
    darkGreen: '#109F10',
    gray: '#757575',
    dark_gray: '#333333',
    mediumGray: '#888888',
    mediumGray2: '#B3B3B3',
    lightGray: '#dddddd',
    lightGray2: '#EEEEEE',
    yellow: '#ffee02',
    orange: '#ffcc80',
    purple: '#311b92',
    light_purple: '#6746c3',
    white: '#ffffff',
    almost_white: '#fdfffc',
    almost_black: '#011627',
    black: '#000000',
    cyan: '#79D5FA',
    dark_cyan: '#2ec4b6',
    light_cyan: '#CEF1D7',
    transparent: '#00000000',
    platinum: '#EAEAEA',
};

export default AppColor;
