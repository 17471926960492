import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';

export const styles = StyleSheet.create({
    icon: {
        width: 52,
        height: 52,
        margin: 2,
        marginBottom: 8,
        marginRight: 8,
        borderRadius: 5,
        color: AppColor.dark_cyan,
    },
    language: {
        position: 'absolute',
        width: 24,
        height: 24,
        borderRadius: 6,
        top: 0,
        right: 0,
    },
    level: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    }
});
