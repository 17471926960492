import React from 'react';

import {View, Image} from 'react-native';
import ClickableText from '../../components/Text/ClickableText'
import {styles} from './webTopBarComponent_styles';
import {BaseComponent} from '../../components/Base/baseComponent';
import AppStyles from '../appStyles';
import CTouchable from '../../components/Button/CTouchable';
import CTab from '../../components/Tab/CTab';
// import CLoginInfoView from '../../components/SideMenu/CLoginInfoView';
import {AppInstance, SCREEN_WRITER} from '../../app';
import {LocalesModule} from '../../modules/LocalesModule';

interface WebTopBarComponentProps {
    navigation?: any;
    route?: any;
    activeTab?: number;
    onTabChosen?: (activeIndex: number) => void;
}

class WebTopBarComponent extends BaseComponent<WebTopBarComponentProps> {
    constructor(props: any) {
        super(props);
    }

    onTabChosen(index: number) {
        if (!AppInstance.isLoggedIn()) {
            if (this.props.route.name == 'index') {
                this.props.onTabChosen && this.props.onTabChosen(index);
            } else {
                this.props.navigation.navigate('index', {activeTab: index});
            }
            return;
        }
        switch (index) {
            case 1:
                this.navigate(SCREEN_WRITER);
                break;
            case 2:
                this.navigate('contacts');
                break;
            default:
                this.navigate('home');
        }
    }

    render() {
        const tabLabel2 = AppInstance.isLoggedIn() ? LocalesModule.top_bar.contacts : LocalesModule.top_bar.contact_us;
        const badgeNumber = 0;

        const items = [
            {label: LocalesModule.product_name},
            {label: LocalesModule.top_bar.writer},
            {label: tabLabel2, badgeNumber: badgeNumber},
        ];
        const tabIndex = this.props.activeTab ?? 0;
        // const loginTab = tabIndex >= items.length ? tabIndex - items.length : undefined;
        const homeScreen = AppInstance.isLoggedIn() ? 'home' : 'index';

        return (
            <View style={styles.menuBar}>
                <CTouchable onPress={() => this.props.navigation.navigate(homeScreen)}>
                    <Image source={require('../../Images/icon.png')} style={styles.icon} />
                </CTouchable>
                <CTab
                    labelStyle={styles.label}
                    activeStyle={styles.activeLabel}
                    itemStyle={styles.tabItemStyle}
                    activeIndex={tabIndex}
                    items={items}
                    onItemSelected={this.onTabChosen.bind(this)}
                ></CTab>
                <View style={AppStyles.flex1}></View>
                {/*<CLoginInfoView navigation={this.props.navigation} activeIndex={loginTab} />*/}
                <ClickableText title={LocalesModule.common.help} style={styles.help} onPress={() => {
                    this.navigate('help')
                }}/>
            </View>
        );
    }
}

export default WebTopBarComponent;
