import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 20,
    },
    helpDetails: {
        fontSize: 16,
        marginTop: 10,
    }
});

export default styles;
