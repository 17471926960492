import {TouchableOpacity, View} from 'react-native';
import {PureComponent} from 'react';
import * as React from 'react';
import {TouchableActiveOpacity} from '../../classes/appContants';

interface CTouchableProps {
    style?: any;
    onPress?: (event?: any) => void;
    testID?: string;
    hidden?: boolean;
    activeOpacity?: number;
    children?: React.ReactNode;
}

class CTouchable extends PureComponent<CTouchableProps> {
    render() {
        if (this.props.hidden) {
            return null;
        }
        if (!this.props.onPress) {
            if (!this.props.style) {
                return this.props.children;
            }
            return (
                <View testID={this.props.testID} style={this.props.style}>
                    {this.props.children}
                </View>
            );
        }
        return (
            <TouchableOpacity
                testID={this.props.testID}
                style={this.props.style}
                onPress={this.props.onPress}
                activeOpacity={this.props.activeOpacity || TouchableActiveOpacity}
            >
                {this.props.children}
            </TouchableOpacity>
        );
    }
}

export default CTouchable;
