import React from 'react';
import {View, Text} from 'react-native';
import SectionHeader from '../../components/Text/SectionHeader';
import BaseScreen from '../Base/baseScreen';
import AppStyles from '../appStyles';
import {LocalesModule} from '../../modules/LocalesModule';
import styles from './helpScreen_styles';

export default class HelpScreen extends BaseScreen {
    constructor(props: any) {
        super(props);
        this.title = LocalesModule.top_bar.help;
    }

    getMainView() {
        return (
            <View style={AppStyles.screen}>
                <SectionHeader title={LocalesModule.help.help_section_signup} />
                <Text style={styles.helpDetails}>
                    {LocalesModule.help.help_signup_details}
                </Text>
                <SectionHeader title={LocalesModule.help.help_section_play} />
                <Text style={styles.helpDetails}>
                    {LocalesModule.help.help_play_details}
                </Text>
                <SectionHeader title={LocalesModule.help.help_section_calendar} />
                <Text style={styles.helpDetails}>
                    {LocalesModule.help.help_calendar_details}
                </Text>
                <SectionHeader title={LocalesModule.help.help_section_newBook} />
                <Text style={styles.helpDetails}>
                    {LocalesModule.help.help_newBook_details}
                </Text>
                <SectionHeader title={LocalesModule.help.help_section_publish} />
                <Text style={styles.helpDetails}>
                    {LocalesModule.help.help_publish_details}
                </Text>
                <SectionHeader title={LocalesModule.help.help_section_unregister} />
                <Text style={styles.helpDetails}>
                    {LocalesModule.help.help_delete_account_details}
                </Text>
            </View>
        );
    }
}
