import React from 'react';
import {Animated} from 'react-native';
import {BaseComponent} from '../Base/baseComponent';

export type AnimatedHideViewDismissType = 'opacity' | 'slide';

interface AnimatedHideViewProps {
    visible?: boolean;
    animationDuration?: number;
    unmountOnHide?: boolean;
    animationType?: AnimatedHideViewDismissType;
    style?: any;
    children?: React.ReactNode;
}

export default class AnimatedHideView extends BaseComponent<AnimatedHideViewProps> {
    opacity?: Animated.Value;
    animatedHeight?: Animated.Value;
    componentHeight?: number;
    unmount: boolean = false;

    constructor(props: AnimatedHideViewProps) {
        super(props);
        if (props.animationType != 'slide') this.opacity = new Animated.Value(props.visible ? 1 : 0);
    }

    shouldComponentUpdate(nextProps: AnimatedHideViewProps, _nextState: any) {
        if (this.props.visible !== nextProps.visible) {
            this.unmount = false;

            if (this.animatedHeight) {
                const componentHeight = this.componentHeight ? this.componentHeight : 0;
                const currentHeight = nextProps.visible ? 0 : componentHeight;
                const newHeight = nextProps.visible ? componentHeight : 0;

                this.animatedHeight.setValue(currentHeight);

                Animated.timing(this.animatedHeight, {
                    toValue: newHeight,
                    duration: this.props.animationDuration ? this.props.animationDuration : 500,
                    useNativeDriver: false,
                }).start(() => {
                    if (this.props.unmountOnHide && newHeight == 0) {
                        this.unmount = true;
                        this.forceUpdate();
                    }
                });
            }
            if (this.opacity) {
                Animated.timing(this.opacity, {
                    toValue: this.props.visible ? 0 : 1,
                    duration: this.props.animationDuration ? this.props.animationDuration : 1000,
                    useNativeDriver: true,
                }).start(() => {
                    if (this.props.unmountOnHide) {
                        this.unmount = true;
                        this.forceUpdate();
                    }
                });
            }
        }
        console.log('AnimatedHideView shouldComponentUpdate: ');
        return true;
    }

    onLayout(event: any) {
        if (!this.componentHeight) {
            this.componentHeight = event.nativeEvent.layout.height;
            if (this.componentHeight && this.props.animationType == 'slide')
                this.animatedHeight = new Animated.Value(this.componentHeight);
        }
    }

    render() {
        const renderStyle = {
            opacity: this.opacity,
            zIndex: this.props.visible ? 1 : 0,
            height: this.animatedHeight,
        };

        const pointerEvents = this.props.visible ? 'auto' : 'none';

        if (this.unmount && !this.props.visible) {
            return null;
        }

        return (
            <Animated.View
                onLayout={(event: any) => this.onLayout(event)}
                pointerEvents={pointerEvents}
                style={[renderStyle, this.props.style]}
            >
                {this.props.children}
            </Animated.View>
        );
    }
}
