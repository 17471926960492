import React from 'react';
import {View, Text} from 'react-native';
import {BaseComponent} from '../Base/baseComponent';
import AppColor from '../../classes/appColor';

interface BadgeViewProps {
    hidden?: boolean;
    number?: number;
    size?: number;
    round?: boolean;
    gray?: boolean;
    style?: any;
}

export default class BadgeView extends BaseComponent<BadgeViewProps> {
    constructor(props: BadgeViewProps) {
        super(props);
    }

    render() {
        const number = this.props.number;

        const size = this.props.size || 18;
        if (this.props.hidden || !number) {
            return null;
        }
        const width = this.props.round || number < 10 ? size : (String(number).length / 1.5) * size;

        const backColor = this.props.gray ? AppColor.lightGray : AppColor.red;
        const textColor = this.props.gray ? AppColor.dark_gray : AppColor.white;

        const style: any = {
            width: width,
            height: size,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            borderRadius: size / 2,
            backgroundColor: backColor,
            borderWidth: 1,
            borderColor: backColor,
        };
        const textStyle = {
            color: textColor,
            fontSize: size - 5,
        };
        return (
            <View style={[style, this.props.style]}>
                <Text style={textStyle} numberOfLines={1}>
                    {number.toString()}
                </Text>
            </View>
        );
    }
}
