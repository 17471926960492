import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    container: {
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        borderRadius: 3,
    },
    icon: {
        marginLeft: 10,
        width: 24,
        height: 24,
        borderRadius: 12,
    },
    bannerText: {
        flex: 1,
        textAlign: 'center',
        color: 'white',
        margin: 16,
    },
    iconClose: {
        color: 'white',
        paddingRight: 10,
    },
});

export default styles;
