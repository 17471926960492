import ServerModule, { BlobTYPE, OnProgressCallback } from './serverModule';
// import convertToProxyURL from 'react-native-video-cache';
// import PlatformModule from './PlatformModule';
import { ShortUrl } from '../classes/shortUrl';
import UrlModule from './urlModule';
//import FetchBlobModule from './fetchBlobModule';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import FileSystemModule from './FileSystemModule';
//import Utils from '../utils/utils';

export enum UrlState {
    server,
    local,
    both,
}

class CacheModuleClass {
    urlMap: Map<ShortUrl, UrlState> = new Map();
    proxyUrlMap: Map<string, string> = new Map();

    constructor() {
        this.init();
    }

    init() {
        // UrlModule.initFolder(BlobTYPE.image).then(()=>{});
        // UrlModule.initFolder(BlobTYPE.voice).then(()=>{});
        // UrlModule.initFolder(BlobTYPE.avatar).then(()=>{});
    }

    async getLocalUrl(id: number | string, type: BlobTYPE, onProgress?: OnProgressCallback): Promise<ShortUrl | undefined> {
        const url = UrlModule.init(id.toString(), type);
        if (!url) {
            return undefined;
        }
        console.log('getLocalUrl: ', this.isUrlLocal(url));
        return await this.downloadToLocal(url, onProgress);
    }

    public getBlobSource(url: ShortUrl | undefined): any {
        if (!url) {
            return undefined;
        }
        return {
            uri: this.getPlayUrl(url),
            headers: {
                authorization: ServerModule.getBearerToken(),
            },
        };
    }

    getPlayUrl(shortUrl: ShortUrl) {
        if (shortUrl.startsWith('file://')) {
            return shortUrl;
        }
        const serverUrl = UrlModule.getServerUrl(shortUrl);
        return serverUrl;
    }

    isUrlLocal(shortUrl: ShortUrl): boolean {
        if (shortUrl.startsWith('file://')) {
            return true;
        }
        const state = this.urlMap.get(shortUrl);
        return state == UrlState.both || state == UrlState.local;
    }

    isServerUrl(shortUrl: ShortUrl): boolean {
        const state = this.urlMap.get(shortUrl);
        return state != UrlState.local;
    }

    setUrlState(shortUrl: ShortUrl, state: UrlState) {
        if (shortUrl) {
            this.urlMap.set(shortUrl, state);
            this.save().then(() => {
            });
        }
    }

    async getUrl(id: string | number, type: BlobTYPE, useCache: boolean = true): Promise<string | undefined> {
        const key = type + '/' + id;
        let url: string | undefined;
        if (useCache) {
            url = this.proxyUrlMap.get(key);
            if (url) {
                return url;
            }
        }
        url = 'blob/url/' + key;
        let json = await ServerModule.apiCall(url, 'GET');
        url = (json && json.url) || undefined;
        // if (url && useCache && PlatformModule.isIOS()) {
        //     url = convertToProxyURL(url);
        //     this.proxyUrlMap.set(key, url);
        //     this.saveProxyUrlMap().then(() => {});
        // }
        return url;
    }

    async downloadToLocal(url: ShortUrl, _onProgress?: OnProgressCallback): Promise<ShortUrl | undefined> {
        // if (url.toLowerCase().startsWith('http')) {
        //     return this.cacheOutSiteUrl(url, onProgress);
        // }
        const exists = this.isUrlLocal(url);
        if (url && !exists) {
            console.log('downloadToLocal: ', url);
            // const localName = UrlModule.getLocalUrl(url);
            // const localUrl = await FetchBlobModule.downloadBlob(
            //     UrlModule.getServerUrl(url),
            //     ServerModule.getAuthorizedHeader(),
            //     localName,
            //     onProgress,
            // );
            // if (localUrl && (await UrlModule.localFileExists(url))) {
            //     this.setUrlState(url, UrlState.both);
            // } else {
            //     return undefined;
            // }
        } else {
            console.log('downloadToLocal exists: ', url);
        }
        return url;
    }

    // public async downloadResource(resUri: string, name: string): Promise<string | undefined> {
    //     // const shortUrl = ShortUrlID + 'image/' + name;
    //     //
    //     // if (!(await UrlModule.localFileExists(shortUrl))) {
    //     //     const localUrl = UrlModule.getLocalUrl(shortUrl);
    //     //     await FileSystemModule.downloadResource(resUri, localUrl);
    //     // }
    //     // CacheModule.setUrlState(shortUrl, UrlState.both);
    //     //return shortUrl;
    // }

    public async clear() {
        this.urlMap = new Map();
        this.proxyUrlMap = new Map();
        await AsyncStorage.removeItem('urlMap');
        await AsyncStorage.removeItem('proxyUrlMap');
        this.init();
    }

    public async save() {
        await AsyncStorage.setItem('urlMap', JSON.stringify(Array.from(this.urlMap.entries())));
    }

    public async saveProxyUrlMap() {
        await AsyncStorage.setItem('proxyUrlMap', JSON.stringify(Array.from(this.proxyUrlMap.entries())));
    }

    public printUrlMap() {
        console.log('CacheModule URL Map: ', [...this.urlMap.entries()]);
    }

    public async load(): Promise<any> {

        const urlMap = await AsyncStorage.getItem('urlMap');
        // console.log('Cache folder: ', FileSystemModule.getDocumentFolder());
        if (urlMap) {
            this.urlMap = new Map(JSON.parse(urlMap));
            this.printUrlMap();
        }
        // const proxyUrlMap = await AsyncStorage.getItem('proxyUrlMap');
        // if (proxyUrlMap) {
        //     this.proxyUrlMap = new Map(JSON.parse(proxyUrlMap));
        // }
        // if (PlatformModule.isIOS()) {
        //     convertToProxyURL(''); /// init Proxy Server.
        // }
    }
}

const CacheModule = new CacheModuleClass();
export default CacheModule;
