import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 30,
        paddingTop: 50,
        backgroundColor: AppColor.backgroundColor,
    },
    horizontal_row: {
        flexDirection: 'row',
        flex: 1,
        backgroundColor: AppColor.backgroundColor,
    },
    title: {
        paddingBottom: 30,
    },
    faceBookLogin: {
        alignSelf: 'center',
        marginTop: 20,
    },
    form: {
        flex: 1,
        margin: 16,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: AppColor.backgroundColor,
    },
    scroll: {
        flex: 1,
        backgroundColor: AppColor.backgroundColor,
    },
    term: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: AppColor.backgroundColor,
    },
    loginButton: {
        paddingVertical: 5,
    },
    facebookLoginButton: {
        alignSelf: 'center',
        paddingVertical: 5,
    },
});

export default styles;
