import React from 'react';
import {View, Text} from 'react-native';
import BaseScreen from '../Base/baseScreen';
import AppStyles from '../appStyles';
import {LocalesModule} from "../../modules/LocalesModule";
import SwitchView from "../../components/View/SwitchView";

export default class ActivatedScreen extends BaseScreen {

    result? : string;

    constructor(props: any) {
        super(props);
        this.title = LocalesModule.signup.activated;
        this.result = this.props.route.params?.result;
    }

    getMainView() {
        let info = LocalesModule.formatString( LocalesModule.signup.activated_info, LocalesModule.product_name );
        return (
            <View style={AppStyles.screen}>
                <SwitchView first={!this.result} >
                    <Text style={AppStyles.mainText}> { info } </Text>
                    <Text style={AppStyles.mainText}> { LocalesModule.signup.activated_error } </Text>
                </SwitchView>
            </View>
        );
    }
}
