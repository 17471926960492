import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor';

const styles = StyleSheet.create({
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: '900',
        color: AppColor.gray,
        fontSize: 20,
        justifyContent: 'space-evenly',
    },
    content: {
        marginTop: 8,
        marginBottom: 8,
        fontSize: 16,
    },
});

export default styles;
