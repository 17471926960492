const CryptoJS = require('react-native-crypto-js');

export class CryptServiceClass {
    myKey = 'Hangzhou2001';

    encodeHex(text: string): string {
        let b64 = CryptoJS.AES.encrypt(text, this.myKey).toString();
        let e64 = CryptoJS.enc.Base64.parse(b64);
        let code = e64.toString(CryptoJS.enc.Hex);
        return code;
    }

    decodeHex(code: string): string {
        let reb64 = CryptoJS.enc.Hex.parse(code);
        let bytes = reb64.toString(CryptoJS.enc.Base64);
        let decrypt = CryptoJS.AES.decrypt(bytes, this.myKey);
        let plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
    }
}

const CryptService = new CryptServiceClass();
export default CryptService;
