import React from 'react';
import Modal from 'react-native-modal';
import {Animation} from 'react-native-animatable';
import {View} from 'react-native';
import HideableView from '../View/HideableView';
import PlatformModule from '../../modules/PlatformModule';
import WebModal from 'react-modal';

interface PopupProps {
    visible: boolean;
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    center?: boolean;
    backdropOpacity?: number;
    onRequestClose?: () => void;
    animationOut?: Animation;
    animationIn?: Animation;
    onBackdropPress?: () => void;
    children?: React.ReactNode;
}

class Popup extends React.PureComponent<PopupProps> {
    render() {
        let backdropOpacity = this.props.backdropOpacity ? this.props.backdropOpacity : 0;
        const top = this.props.top ? this.props.top : 0;

        if (PlatformModule.isWeb()) {
            const backgroundColor = !backdropOpacity ? '#00000000' : '#88888888';

            const style: any = this.props.center
                ? {
                      content: {
                          top: '50%',
                          left: '50%',
                          right: 'auto',
                          bottom: 'auto',
                          marginRight: '-50%',
                          transform: 'translate(-50%, -50%)',
                      },
                      overlay: {
                          backgroundColor: backgroundColor,
                      },
                  }
                : {
                      content: {
                          top: this.props.top,
                          left: this.props.left,
                          right: this.props.right,
                          bottom: this.props.bottom,
                      },
                      overlay: {
                          backgroundColor: backgroundColor,
                      },
                  };

            WebModal.setAppElement('#root');
            return (
                <WebModal
                    isOpen={this.props.visible}
                    onAfterOpen={() => {}}
                    onRequestClose={this.props.onRequestClose}
                    style={style}
                    contentLabel="WebPopup"
                >
                    {this.props.children}
                </WebModal>
            );
        }

        const style: any = this.props.center
            ? {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
              }
            : this.props.right
            ? {
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
              }
            : {
                  position: 'absolute',
                  top: this.props.top,
                  left: this.props.left,
                  right: this.props.right,
                  bottom: this.props.bottom,
              };

        return (
            <Modal
                style={style}
                hasBackdrop={true}
                backdropColor="black"
                backdropOpacity={backdropOpacity}
                onBackdropPress={this.props.onBackdropPress}
                isVisible={this.props.visible}
                animationIn={this.props.animationIn ? this.props.animationIn : 'fadeIn'}
                animationOut={this.props.animationOut ? this.props.animationOut : 'fadeOut'}
            >
                <HideableView hidden={!this.props.right || top == 0}>
                    <View style={{height: top, width: 1}} />
                </HideableView>
                {this.props.children}
            </Modal>
        );
    }
}

export default Popup;
