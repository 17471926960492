import React, {PureComponent} from 'react';
import AppColor from '../../classes/appColor';
import {Ref} from '../../classes/ref';
import PlatformModule from '../../modules/PlatformModule';
import {TextInput} from "react-native";

interface CInputProps {
    hidden?: boolean;
    placeholder?: string;
    autoCapitalize?: string;
    autoCorrect?: boolean;
    field?: string;
    error?: boolean;
    style?: any;
    hideClearAll?: boolean;
    defaultValue?: string;
    autoFocus?: boolean;
    editable?: boolean;
    multiline?: boolean;
    numberOfLines?: number;
    secureTextEntry?: boolean;
    onChangeText?: (value: string)=>void;
    scrollEnabled?: boolean;
}

export default class CInput extends PureComponent<CInputProps> {
    _ref = new Ref();

    componentDidMount() {
        if (this.props.autoFocus)
            setTimeout(() => {
                this.focus();
            }, 200);
    }

    focus() {
        this._ref.component?.focus();
    }

    clear() {
        this._ref.component?.clear();
    }

    render() {
        if (this.props.hidden) {
            return null;
        }
        let {style, ...passThroughProps} = this.props as any;
        const inputStyle: any = PlatformModule.isWeb()
            ? {
                  color: AppColor.dark_gray,
                  outlineColor: AppColor.primaryColor,
                  fontSize: 17,
              }
            : {
                  color: AppColor.dark_gray,
                  fontSize: 17,
              };
        return (
            <TextInput
                {...passThroughProps}
                defaultValue={this.props.defaultValue}
                autoCapitalize={this.props.autoCapitalize}
                autoCorrect={this.props.autoCorrect}
                secureTextEntry={this.props.secureTextEntry}
                placeholder={this.props.placeholder}
                placeholderTextColor={AppColor.lightGray}
                style={[inputStyle, style]}
                clearButtonMode={this.props.hideClearAll ? 'never' : 'while-editing'}
                editable={this.props.editable}
                multiline={this.props.multiline}
                numberOfLines={this.props.numberOfLines}
                onChangeText={this.props.onChangeText}
                scrollEnabled={this.props.scrollEnabled}
                ref={this._ref.ref}
            />
        );
    }
}
