import { Language } from '../screens/Invitation/invitation'

class IconModuleClass {
    constructor() {}

    public getImageImportIcon() {
        return './FontAwesome5/file-import';
    }

    public getPlus() {
        return './MaterialCommunityIcons/plus';
    }

    public getSoundIcon() {
        return './AntDesign/sound';
    }

    public getLanguageIcon(language?: Language) {
        if (!language) {
            return undefined;
        }
        return language == Language.chinese ? require('../Images/chinese.jpg') : require('../Images/english.jpg');
    }

    public getPlayIcon() {
        return require('../Images/play.png');
    }

    public getPeopleIcon() {
        return './Ionicons/people';
    }

    public getResultIcon(right: boolean) {
        return right ? require('../Images/right.png') : require('../Images/wrong.png');
    }

    public getDeletableSectionIcon(isDeleteMode: boolean) {
        return isDeleteMode ? './MaterialIcons/done' : './FontAwesome/trash-o';
    }

    public getUserIcon(icon?: string) {
        return icon ? icon : require('../Images/icon.png');
    }
}

const IconModule = new IconModuleClass();
export default IconModule;
