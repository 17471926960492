import { ShortUrl } from './shortUrl';

export enum Language {
    none = '',
    english = 'en',
    chinese = 'zh'
}

export enum friendStatus {
    stranger,
    requested,
    received,
    friend,
}

export interface BasicUser {
    id: number;
    name: string;
    avatar?: ShortUrl;
    email?: string;
}

export class User {
    public id: number = 0;
    public email = '';
    public isFemale = false;
    public name = '';
    public avatar?: ShortUrl;
    public description = '';
    public level: number = 0;
    public score: number = 0;
    public smile: number = 0;       /// good learning behavior number.
    public bestPlayer: number = 0;  /// best player number.
    public followings: number = 0;
    public followers: number = 0;
    public followed?: boolean;
    public status?: number;
    public firstLanguage?: Language;
    public readingLanguage?: Language;
    public myPlan: number = 0;
    public learningLanguages: Language[] = [];
    public areaText?: string[];

    constructor(json?: any) {
        Object.assign(this, json);
    }

    public getInfo(): BasicUser {
        console.log('Get User info: ');
        return {id: this.id, name: this.name, avatar: this.avatar, email: this.email};
    }
}
