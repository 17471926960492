//import PlatformModule from './PlatformModule';
//import {OnProgressCallback} from './serverModule';
//import FileSystemModule from './FileSystemModule';
//import RNFetchBlob from "rn-fetch-blob";

export class FetchBlobModuleClass {
    constructor() {}

    public getFileBlob(fullUrl: string, header?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', fullUrl);
            if (header && header.Authorization) {
                xhr.setRequestHeader('Authorization', header.Authorization);
            }
            xhr.responseType = 'blob';
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function () {
                reject();
            };
            xhr.send();
        });
    }

    public async uploadBlob(url: string, header: any, file: any): Promise<any> {
        const fileObj = typeof file === 'string' ? await this.getFileBlob(file) : file;
        return fetch(url, {
            method: 'POST',
            headers: header,
            body: fileObj,
        })
            .then((response) => {
                return response.text();
            })
            .then((response) => {
                return response;
            })
            .catch((error) => console.log('uploadBob: ' + error));
    }

    public async downloadBlob(
        url: string,
        header: any,
        // localName: string,
        // onProgress?: OnProgressCallback,
    ): Promise<string | URL | undefined> {
        if (!url) {
            return undefined;
        }
        return this.getFileBlob(url, header).then((blob: any) => {
            return URL.createObjectURL(blob);
        });
    }
}

const FetchBlobModule = new FetchBlobModuleClass();
export default FetchBlobModule;
