import React from 'react';

import { View } from 'react-native';
import { styles } from './bookIconComponent_styles';
import CImage from '../../components/Image/CImage';
import { Language } from '../../modules/languageModule';

interface BookIconComponentProps {
    icon?: string;
    language?: Language;
    level?: number;
}

export function BookIconComponent(props: BookIconComponentProps) {
    return <View>
        <CImage style={styles.icon} name={props.icon}/>
    </View>
}
