import React from 'react';
import { Text, View } from 'react-native'
import {LocalesModule} from '../../modules/LocalesModule';
import BaseScreen from '../Base/baseScreen';
import AppstoreComponent from '../Web/appstoreComponent'
import styles from './downloadPlayScreen_styles';

export default class DownloadPlayScreen extends BaseScreen {

    constructor(props: any) {
        super(props);
        this.title = LocalesModule.top_bar.download;
    }

    getMainView() {
        return (
            <View style={styles.container}>
                <Text style={styles.text}> { LocalesModule.invite.download_to_play } </Text>
                <AppstoreComponent/>
            </View>
        );
    }
}
