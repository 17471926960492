import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 30,
        backgroundColor: AppColor.backgroundColor,
    },
    scroll: {
        flex: 1,
        backgroundColor: AppColor.backgroundColor,
    },
});

export default styles;
