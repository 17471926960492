import * as React from 'react';
import {Ref} from './classes/ref';
import PlatformModule from './modules/PlatformModule';
import {getStateFromPath, getPathFromState, NavigationContainer, CommonActions} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import DownloadPlayScreen from './screens/Invitation/downloadPlayScreen';
import LoginScreen from './screens/Login/login';
import SignupScreen from './screens/Login/signup';
import InvitationScreen from './screens/Invitation/invitationScreen';
import AgreementScreen from './screens/Login/agreementScreen';
import PasswordResetScreen from './screens/Login/passwordResetScreen';
import HelpScreen from './screens/Web/helpScreen';
import WelcomeScreen from './screens/Web/welcome';
import NavigationBarItem, { NavigationItem } from './components/View/NavigationBarItem';
import AppColor from './classes/appColor';
import BaseScreen from './screens/Base/baseScreen';
import Utils from './utils/utils';
import AboutScreen from './screens/Web/aboutScreen';
import ContactUsScreen from './screens/Web/contactUsScreen';
import ActivatedScreen from './screens/Web/activatedScreen';

export let AppInstance: App;

export const SCREEN_MAIN = 'app';
export const SCREEN_MODAL = 'modal';
export const SCREEN_WRITER = 'writer';

const navigationOptions : any = ({navigation}: { [id: string]: any }) => ({
    headerStyle: {
        backgroundColor: AppColor.headerColor,
    },
    headerTitleAlign: 'center',
    headerShown: !PlatformModule.isWeb(),
    headerTitle: () => <NavigationBarItem name={NavigationItem.title} navigation={navigation}/>,
    headerLeft: () => <NavigationBarItem name={NavigationItem.left} navigation={navigation}/>,
    headerRight: () => <NavigationBarItem name={NavigationItem.right} navigation={navigation}/>,
});

class App extends React.Component {
    state: any = {loggedIn: false, landing: true, initUrl: undefined};
    navigatorRef = new Ref();

    constructor(props: any) {
        super(props);
        AppInstance = this;
    }

    isLoggedIn() {
        return this.state.loggedIn;
    }

    async onLandingSuccess(_initUrl?: string) {
    }

    async onLoginSuccess(_screen: BaseScreen) {
    }

    updateState(loggedIn: boolean, initUrl?: string) {
        if (initUrl) {
            this.state.initUrl = initUrl;
        }
        this.setState({loggedIn, landing: false});
    }

    getNavigation() {
        return this.navigatorRef.component;
    }

    navigate(isModal: boolean, screen: string, params: any) {
        const stack = isModal ? SCREEN_MODAL : SCREEN_MAIN;
        const json = {
            screen: screen,
            params: params,
        };
        this.getNavigation()?.navigate(stack, json);
    }

    goHome(navigation: any) {
        const parentScreen = 'index';
        const newParams = {screen: parentScreen};
        const resetAction = CommonActions.reset({
            index: 0,
            routes: [{name: SCREEN_MAIN, params: newParams}],
        });
        navigation.dispatch(resetAction);
    }

    navigateToPath(navigation: any, path: string, aboveHome?: boolean) {
        const mPath = Utils.getRelativeUrl(path);
        const state = this.getLinkingConfig().getStateFromPath(mPath);
        let routes = state.routes;
        if (aboveHome) {
            const homeRoute = {name: SCREEN_MAIN, params: {screen: 'home'}};
            routes = [homeRoute, ...state.routes];
        }
        this.navigationReset(navigation, routes);
    }

    navigationReset(navigation: any, routes: any) {
        const resetAction = CommonActions.reset({
            index: 0,
            routes: routes,
        });
        navigation.dispatch(resetAction);
    }

    getLinkingConfig(): any {
        const modalPath = '/' + SCREEN_MODAL;
        const mainPath = '/' + SCREEN_MAIN;

        const linking: any = {
            getStateFromPath: (path: string, options: any) => {
                let mpath = path;
                if (mpath == '/') {
                    mpath = '/home';
                }
                const key = '/inv/';
                const index = mpath.indexOf(key);
                if (index >= 0) {
                    const itemId = mpath.substring(index + key.length);
                    mpath = `/inv?id=${itemId}`
                }
                if (!mpath.startsWith(modalPath) && !mpath.startsWith(mainPath)) mpath = mainPath + mpath;
                return getStateFromPath(mpath, options);
            },
            getPathFromState(state: any, config: any) {
                let path = getPathFromState(state, config);
                if (path.startsWith(mainPath)) path = path.substring(mainPath.length);
                if (path == '/home' || path == '/index') {
                    path = '/';
                }
                return path;
            },
        };
        return linking;
    }

    getModalStack() {
        const ModalStack = createStackNavigator();
        return (
            <ModalStack.Navigator screenOptions={navigationOptions}>
                <ModalStack.Screen name="about" component={AboutScreen} />
                <ModalStack.Screen name="contactus" component={ContactUsScreen} />
                <ModalStack.Screen name="activated" component={ActivatedScreen}/>
            </ModalStack.Navigator>
        );
    }

    getAppStack() {
        const Stack = createStackNavigator();
        return (
                <Stack.Navigator screenOptions={navigationOptions}>
                    <Stack.Screen name="index" options={{headerShown: false}} component={WelcomeScreen}/>
                    <Stack.Screen name="login" component={LoginScreen}/>
                    <Stack.Screen name="signup" component={SignupScreen}/>
                    <Stack.Screen name="inv" component={InvitationScreen}/>
                    <Stack.Screen name="downloadPlay" component={DownloadPlayScreen}/>
                    <Stack.Screen name="agreement" component={AgreementScreen}/>
                    <Stack.Screen name="passwordReset" component={PasswordResetScreen}/>
                    <Stack.Screen name="about" component={AboutScreen} />
                    <Stack.Screen name="help" component={HelpScreen} />
                    <Stack.Screen name="contactus" component={ContactUsScreen} />
                    <Stack.Screen name="activated" component={ActivatedScreen}/>
                </Stack.Navigator>
        );
    }

    render() {

        const RootStack = createStackNavigator();
        const MainStack = () => this.getAppStack();
        const ModalStack = () => this.getModalStack();

        return (
            <NavigationContainer ref={this.navigatorRef.ref} linking={this.getLinkingConfig()}>
                <RootStack.Navigator screenOptions={{headerShown: false}}>
                    <RootStack.Screen name={SCREEN_MAIN} component={MainStack} />
                    <RootStack.Screen name={SCREEN_MODAL} component={ModalStack} />
                </RootStack.Navigator>
            </NavigationContainer>
        );
    }
}

export default App;
