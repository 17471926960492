import React from 'react';
import {View, Text} from 'react-native';
import BaseScreen from '../Base/baseScreen';
import AppStyles from '../appStyles';
import {LocalesModule} from "../../modules/LocalesModule";

export default class AboutScreen extends BaseScreen {
    constructor(props: any) {
        super(props);
        this.title = LocalesModule.bottom_bar.about + " " + LocalesModule.product_name;
    }

    getMainView() {
        return (
            <View style={AppStyles.screen}>
                <Text style={AppStyles.mainText}> { LocalesModule.index.about_me } </Text>
            </View>
        );
    }
}
