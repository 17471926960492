import CryptService from './cryptService';
import {Dimensions} from 'react-native';
import uuid from 'react-native-uuid';

let _ = require('lodash/core');

export class Utils {
    static validateEmail(email: string) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static getFileNameWithoutExt(fileName?: string) {
        if (!fileName) {
            return;
        }
        return fileName.substr(0, fileName.lastIndexOf('.'));
    }

    static getFileExt(fileName: string) {
        return fileName.substr(fileName.lastIndexOf('.'));
    }

    static arraysEqual(arr1: any, arr2: any) {
        if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) return false;

        const marr1 = arr1.concat().sort();
        const marr2 = arr2.concat().sort();

        for (let i = 0; i < marr1.length; i++) {
            if (!_.isEqual(marr1, marr2)) return false;
        }
        return true;
    }

    static removeEmptyValues(obj: any) {
        if (obj) {
            Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));
        }
    }

    static swapArrayElements(arr: any[], index1: number, index2: number) {
        if (index1 >= arr.length || index2 >= arr.length) {
            return;
        }
        const temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
    }

    static deepCopy(obj: any) {
        if (!obj) {
            return obj;
        }
        return JSON.parse(JSON.stringify(obj));
    }

    static shuffleArray(array: any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    static getRandomNumber(n1: number, n2: number) : number {
        return Math.round(n1 + Math.random() * (n2 - n1));
    }

    static insertString(dest: string, toInsert: string, index: number) {
        if (index > 0) {
            return dest.substring(0, index) + toInsert + dest.substr(index);
        }
        return toInsert + dest;
    }

    static unique(arr: any[]): any[] {
        return [...new Set(arr)];
    }

    static objectEqual(obj1: any, obj2: any) {
        return _.isEqual(obj1, obj2);
    }

    static sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    static safeJSONParse(str: string): any {
        try {
            const link = JSON.parse(str);
            return link;
        } catch (e) {
            console.log('safeJSONParse error: ', e, str);
            return undefined;
        }
    }

    static getUrlSearchParams(urlStr: string): any {
        let regex = /[?&]([^=#]+)=([^&#]*)/g,
            params: any = {},
            match;
        while ((match = regex.exec(urlStr))) {
            params[match[1]] = match[2];
        }
        return params;
    }

    static getObjectFromCryptedLink(url: string, key: string): any {
        const sKey = '&' + key + '=';
        const segs = url.split(sKey);
        if (segs.length <= 1) {
            return undefined;
        }
        const part = segs[1].split('&')[0];
        return Utils.safeJSONParse(CryptService.decodeHex(part));
    }

    static getRelativeUrl(fullUrl: string) {
        let mPath = fullUrl;
        if (fullUrl.includes('http')) {
            let index = fullUrl.indexOf('://');
            if (index > 0) {
                mPath = fullUrl.substring(index + 3);
                index = mPath.indexOf('/');
                if (index > 0) {
                    mPath = mPath.substring(index);
                }
            }
        }
        return mPath;
    }

    static getUUID() : string {
        return '' + uuid.v4();
    }

    static getFilePath(fullPath?: string): string {
        if (!fullPath) {
            return '';
        }
        return fullPath.substring(0, fullPath.lastIndexOf('/'));
    }

    static getFileName(fullPath?: string): string | undefined {
        if (!fullPath) {
            return undefined;
        }
        const str = fullPath.split('\\').pop();
        if (str) {
            return str.split('/').pop();
        }
        return '';
    }

    static getFileUri(filePath: string) {
        const prefix = 'file://';
        if (filePath.startsWith(prefix)) {
            return filePath;
        }
        return prefix + filePath;
    }

    static getFileUrl(fileUri: string) {
        const prefix = 'file:/';
        if (fileUri.startsWith(prefix)) {
            return fileUri.substring(prefix.length);
        }
        return fileUri;
    }

    static getDurationString(duration?: number) {
        if (duration) {
            return duration.toFixed(2) + "''";
        } else {
            return '';
        }
    }

    static isPortrait() {
        return Dimensions.get('window').width <= Dimensions.get('window').height;
    }

    static colorToString(color?: number): string | undefined {
        if (color == undefined) {
            return undefined;
        }
        const c = (color & 0x00ffffff).toString(16).toUpperCase();
        return '#00000'.substring(0, 7 - c.length) + c;
    }

    static colorFromString(sColor?: string): number | undefined {
        return !sColor ? undefined : Number.parseInt(sColor.substring(1, 7), 16);
    }

    static findLast(array: any[], predicate: (item: any)=>boolean) {
        for (let i = array.length - 1; i >= 0; --i) {
            const x = array[i];
            if (predicate(x)) {
                return x;
            }
        }
    }
}

export default Utils;
