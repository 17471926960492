import React from 'react';

import { styles } from './competitionItemComponent_styles';
import CImage from '../../components/Image/CImage';
import { Competition } from './competition';
import { Text, View } from 'react-native';
import IconModule from '../../modules/IconModule';
import UserInfoMiniComponent from '../../components/Info/UserInfoMiniComponent';
import DateTimeUtils from '../../utils/dateTimeUtils';
import CTouchable from '../../components/Button/CTouchable';
import HideableView from '../../components/View/HideableView';

interface CompetitionItemProps {
    competition: Competition;
    hideDetails?: boolean;
    onClicked?: ()=>void;
    style?: any;
}

export function CompetitionItemComponent(props: CompetitionItemProps) {

    const item = props.competition;

    return <CTouchable style={ [styles.item, props.style] } onPress={props.onClicked}>
        <View style={styles.bookRow}>
            <CImage style={styles.icon} name={item.icon}/>
            <CImage style={styles.language} name={IconModule.getLanguageIcon(item.language)}/>
            <View style={styles.titleRow}>
                <Text numberOfLines={1} style={styles.title}>{item.bookName}</Text>
            </View>
            <Text
                style={styles.time}> {item.time && DateTimeUtils.formatDate(new Date(item.time)) || ''} </Text>
        </View>
        <HideableView hidden={props.hideDetails}>
            <View style={styles.participantsRow}>
                <UserInfoMiniComponent player={item.participants[0]}/>
                <Text style={styles.vs}> {' VS '} </Text>
                <UserInfoMiniComponent player={item.participants[1]}/>
            </View>
            <View style={styles.resultRow}>
                <Text style={styles.score}> {item.participants[0].score} </Text>
                <Text style={styles.score}> {':'} </Text>
                <Text style={styles.score}> {item.participants[1].score} </Text>
            </View>
        </HideableView>
    </CTouchable>

};
