import CForm from '../../components/Form/CForm'
import styles from './styles';
import React from 'react';
import CButton from '../../components/Button/CButton';
import ServerModule from '../../modules/serverModule';
import Utils from '../../utils/utils';
import ClickableText from '../../components/Text/ClickableText';
import BaseScreen from '../Base/baseScreen';
import AppStyles from '../appStyles';
import {View} from 'react-native';
import {BannerType} from '../../components/Banner/CBanner';
import Input from '../../components/Input/CInput';
import {AppInstance} from '../../app';
import {LocalesModule} from '../../modules/LocalesModule';
import FBLoginButton from '../../components/FacebookLogin/facebookLoginButton';

class LoginScreen extends BaseScreen {
    state: {[id: string]: any} = {
        allFieldsValid: false,
    };

    fields: any = {};
    isSubmitting: boolean = false;
    controls: {[id: string]: any} = {};

    constructor(props: any) {
        super(props);
        this.title = LocalesModule.product_name;
        this.widthLimited = true;
        this.leftButton.icon = undefined;
        this.webTabIndex = 3;
    }

    handleSubmit() {
        this.isSubmitting = true;
        this.showSpinner(true);

        const invitation = AppInstance.state.initUrl;
        ServerModule.login(this.fields.email, this.fields.password, invitation)
            .then((success) => {
                this.isSubmitting = false;
                this.showSpinner(false);
                if (success) {
                    AppInstance.onLoginSuccess(this);
                } else {
                    console.log('login failed.');
                    this.showBanner(BannerType.error, LocalesModule.login.message.failed);
                }
            })
            .catch((error) => {
                this.alert(error);
            });
    }

    onSignupClick() {
        this.props.navigation.navigate('signup');
    }

    onForgotPassword() {
        this.props.navigation.navigate('passwordReset', {
            email: this.fields.email,
        });
    }

    validateField(field: string, value: any): Promise<string | undefined> {
        let result: any = undefined;
        if (field == 'email' && !Utils.validateEmail(value)) {
            result = LocalesModule.login.message.invalid_email;
        } else if (field == 'password' && (!value || value.length < 3)) {
            result = LocalesModule.login.message.invalid_password;
        }
        return Promise.resolve(result);
    }

    onAllFieldsValidated(bValid: boolean) {
        this.state.allFieldsValid = bValid;
        this.setState(this.state);
    }

    getMainView() {
        return (
            <View style={styles.container}>
                <CForm
                    fieldsValue={this.fields}
                    validateField={this.validateField.bind(this)}
                    onAllFieldsValidated={(valid: boolean) => this.onAllFieldsValidated(valid)}
                >
                    <Input
                        placeholder={LocalesModule.signup.fields.email}
                        field={'email'}
                        autoCorrect={false}
                        error={false}
                    />
                    <Input
                        placeholder={LocalesModule.signup.fields.password}
                        field={'password'}
                        autoCorrect={false}
                        secureTextEntry
                    />
                </CForm>
                <CButton
                    primary={true}
                    style={styles.loginButton}
                    disabled={!this.state.allFieldsValid || this.isSubmitting}
                    onPress={() => this.handleSubmit()}
                    testID={'btnLogin'}
                    title={LocalesModule.login.login}
                    textStyle={{textAlign: 'center'}}
                />
                <FBLoginButton screenRef={this} />
                <ClickableText
                    title={LocalesModule.login.forgot_password}
                    style={AppStyles.link}
                    onPress={() => this.onForgotPassword()}
                />
                <ClickableText
                    title={LocalesModule.signup.signup}
                    style={[AppStyles.link, AppStyles.mgt20]}
                    onPress={() => this.onSignupClick()}
                />
            </View>
        );
    }
}

export default LoginScreen;
