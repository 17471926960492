import React from 'react';
import { View, Text } from 'react-native';
import { Player } from '../../screens/Invitation/player'
import CImage from '../Image/CImage';
import CTouchable from '../Button/CTouchable';
import IconModule from '../../modules/IconModule';
import { DefaultFont } from '../../classes/appContants';
import { User } from '../../classes/user';

interface UserInfoMiniComponentProps {
    user?: User;
    player?: Player;
    imageStyle?: any;
    textStyle?: any;
    style?: any;
}

export default function UserInfoMiniComponent( props: UserInfoMiniComponentProps ) {
    return (
        <View style={[{flexDirection: 'row', alignItems: 'center'}, props.style]}>
            <CTouchable
                onPress={() => {
                }}
            >
                <CImage
                    style={[
                        {
                            width: 34,
                            height: 34,
                            resizeMode: 'cover',
                            borderRadius: 17,
                        },
                        props.imageStyle,
                    ]}
                    name={IconModule.getUserIcon(props.player?.icon || props.user?.avatar)}
                />
            </CTouchable>
            <CTouchable
                onPress={() => {
                }}
            >
                <Text
                    style={[
                        {
                            paddingHorizontal: 4,
                            fontFamily: DefaultFont,
                            fontSize: 15,
                            color: '#575757',
                        },
                        props.textStyle,
                    ]}
                >
                    {props.player?.name || props.user?.name}
                </Text>
            </CTouchable>
        </View>
    );
}
