import React from 'react';
import {View} from 'react-native';
import {AccessToken, LoginButton} from 'react-native-fbsdk';
import styles from '../../screens/Login/styles';
import PlatformModule from '../../modules/PlatformModule';
import {BannerType} from '../Banner/CBanner';
import {LocalesModule} from '../../modules/LocalesModule';
import ServerModule from '../../modules/serverModule';
import UserModule from '../../modules/userModule';
import {AppInstance} from '../../app';
import BaseScreen from '../../screens/Base/baseScreen';
import {BaseComponent} from '../Base/baseComponent';

interface FBLoginButtonProps {
    screenRef: BaseScreen;
}

export default class FBLoginButton extends BaseComponent<FBLoginButtonProps> {
    private webLogInButton: any;
    render() {
        if (PlatformModule.isWeb()) {
            let FacebookLogin = require('react-facebook-login').default;
            this.webLogInButton = (
                <FacebookLogin
                    appId="452182362022690"
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={() => {}}
                    callback={() => {}}
                />
            );
        }
        return (
            <View style={styles.facebookLoginButton}>
                {PlatformModule.isWeb() ? (
                    this.webLogInButton
                ) : (
                    <LoginButton
                        permissions={['email']}
                        onLoginFinished={(error, result) => {
                            if (error) {
                                console.log('login has error: ' + result.error);
                                this.props.screenRef.showBanner(BannerType.error, LocalesModule.login.message.failed);
                            } else if (result.isCancelled) {
                                console.log('login is cancelled.');
                            } else {
                                AccessToken.getCurrentAccessToken().then((data) => {
                                    const accessToken = data?.accessToken.toString();
                                    console.log('ACCESS TOKEN: ' + accessToken);
                                    ServerModule.apiCall('user/facebookLogin', 'POST', {
                                        accessToken: accessToken,
                                        deviceId: UserModule.deviceId,
                                    })
                                        .then((json) => {
                                            console.log('LOGIN SUCCESS: ' + JSON.stringify(json));
                                            UserModule.updateMySelf(json);
                                            AppInstance.onLoginSuccess(this.props.screenRef).then(() => {});
                                        })
                                        .catch((err) => {
                                            console.log('login failed. ' + err);
                                            this.props.screenRef.showBanner(BannerType.error, LocalesModule.login.message.failed);
                                        });
                                });
                            }
                        }}
                        onLogoutFinished={() => console.log('facebook logout')}
                    />
                )}
            </View>
        );
    }
}
