import React from 'react';
import {View} from 'react-native';
import AppStyles from '../../screens/appStyles';
import CImage from '../Image/CImage';
import SwitchView from '../View/SwitchView';
import ClickableText from '../Text/ClickableText';
import BadgeView from '../View/BadgeView';
import AppColor from '../../classes/appColor';
import {BaseComponent} from '../Base/baseComponent';

export class CNavigationButtonConfig {
    disable?: boolean;
    icon?: any;
    color?: string;
    iconNext?: any;         /// the name of the second icon.
    title?: any;
    badgeNumber?: number;
}

export interface CNavigationButtonProps {
    config: CNavigationButtonConfig;
    style?: any;
    testID?: string;
    whiteTheme?: boolean;
    onPress: () => void;
}

class CNavigationButton extends BaseComponent<CNavigationButtonProps> {
    render() {
        const config = this.props.config;
        if (!config || !config.icon && !config.title) return null;

        const onPress = this.props.config.disable ? undefined : this.props.onPress;
        const styles = {
            color: config?.color || (this.props.whiteTheme ? AppColor.white : AppColor.headerTextColor),
            width: 32,
            height: 32,
            opacity: 1.0,
        };
        // console.log('CNavigationButton render: ', this.props.config);
        let color = AppColor.darkPrimaryColor;
        if (this.props.config.disable) {
            styles.opacity = 0.25;
            color = AppColor.lightGray;
        }
        return (
            <SwitchView first={config.icon}>
                <View style={[AppStyles.flexR_center, AppStyles.pd10]}>
                    <CImage testID={this.props.testID} name={config.icon} style={styles} onPress={onPress} activeOpacity={0.6} />
                    <BadgeView size={18} number={config.badgeNumber} />
                </View>
                <View style={[AppStyles.flexR_center, AppStyles.pd4]}>
                    <ClickableText testID={this.props.testID} style={{color: color}} onPress={onPress} title={config.title} />
                    <BadgeView size={18} number={config.badgeNumber} />
                </View>
            </SwitchView>
        );
    }
}

export default CNavigationButton;
