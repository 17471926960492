import React from 'react';
import CTouchable from '../Button/CTouchable';
//import IconNB from "./IconNB";

interface CIconProps {
    hidden?: boolean;
    name: string;
    style?: any;
    onPress?: (event?: any) => void;
    testID?: string;
}

class CIcon extends React.PureComponent<CIconProps> {
    render() {
        if (this.props.hidden) return null;

        const segments = this.props.name.split('/');
        if (segments.length < 2) return null;

        // const type: any = segments[0];
        // const name = segments[1];
        return (
            <CTouchable testID={this.props.testID} onPress={this.props.onPress}>
                {/*<IconNB type={type} style={this.props.style} name={name} />*/}
            </CTouchable>
        );
    }
}

export default CIcon;
