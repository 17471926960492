import React from 'react';
import {View,Text} from 'react-native';
import styles from './styles';
import HideableView from '../View/HideableView';
import AppStyles from '../../screens/appStyles';
import AppColor from "../../classes/appColor";

interface FormItemContainerProps {
    label?: string;
    item: any;
    error?: string;
    style?: any;
}

export default class FormItemContainer extends React.Component<FormItemContainerProps> {
    render() {
        const style = {
            borderBottomWidth: 1.0,
            borderBottomColor: AppColor.lightGray,
            paddingVertical: 4,
            paddingHorizontal: 10,
            marginVertical: 10,
        }
        return (
            <View style={style}>
                <HideableView hidden={!this.props.label}>
                    <Text style={AppStyles.mgx20}>{this.props.label}</Text>
                </HideableView>
                <View style={AppStyles.mgl0}>
                    {this.props.item}
                </View>
                <HideableView hidden={!this.props.error}>
                    <Text style={styles.errorInfo}> {this.props.error} </Text>
                </HideableView>
            </View>
        );
    }
}
