import React from 'react';
import AppColor from '../../classes/appColor';
import {BaseButton} from './BaseButton';
import {Text} from "react-native";
import HideableView from "../View/HideableView";

interface CButtonProps {
    hidden?: boolean;
    style?: any;
    textStyle?: any;
    title: string;
    small?: boolean;
    info?: boolean;
    primary?: boolean;
    block?: boolean;
    success?: boolean;
    disabled?: boolean;
    margin?: number;
    padding?: number;
    testID?: string;
    lowerCase?: boolean;
    onPress: () => void;
    children?: React.ReactNode;
}

class CButton extends React.PureComponent<CButtonProps> {
    render() {
        if (this.props.hidden) return null;

        let padding = this.props.padding;
        if (this.props.padding == undefined) padding = this.props.small ? 5 : 15;

        let margin = this.props.margin;
        if (this.props.margin == undefined) margin = this.props.small ? 0 : 10;

        let backgroundColor = this.props.primary
            ? AppColor.primaryColor
            : AppColor.darkPrimaryColor;
        return (
            <BaseButton
                style={[
                    {
                        margin: margin,
                        padding: padding,
                        backgroundColor: backgroundColor,
                        color: '#ffffff',
                        justifyContent: 'center',
                        alignItems: 'center',
                        opacity: this.props.disabled? 0.5 : 1.0
                    },
                    this.props.style,
                ]}
                small={this.props.small}
                success={this.props.success}
                info={this.props.info}
                onPress={this.props.disabled ? ()=>{} : this.props.onPress}
                testID={this.props.testID}
                disabled={this.props.disabled}
                block={this.props.block}
            >
                <HideableView hidden={!this.props.title} >
                    <Text
                        style={[
                            {
                                fontWeight: 'bold',
                                fontSize: this.props.small ? 14 : 17,
                                textAlign: 'center',
                                color: '#ffffff',
                                paddingLeft: 0,
                                paddingRight: 0,
                            },
                            this.props.textStyle,
                        ]}
                    >
                        {this.props.title}
                    </Text>
                </HideableView>
                {this.props.children}
            </BaseButton>
        );
    }
}

export default CButton;
