import {BaseComponent} from '../Base/baseComponent';
import { getNavigationLeftView, getNavigationRightView, getTitleView } from '../NavigationBar/NavigationBar';

export enum NavigationItem {
    left = 'left',
    right = 'right',
    title = 'title'
}

interface NavigationBarItemProps {
    name: NavigationItem;
    navigation: any;
}

class NavigationBarItem extends BaseComponent<NavigationBarItemProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const navigation = this.props.navigation;
        if (navigation[this.props.name] && !navigation[this.props.name].update) {
            navigation[this.props.name].update = this.forceUpdate.bind(this);
        }
        switch(this.props.name){
            case NavigationItem.left:  return getNavigationLeftView(navigation.screen);
            case NavigationItem.right:  return getNavigationRightView(navigation.screen);
            case NavigationItem.title:  return getTitleView(navigation.screen);
        }
    }
}

export default NavigationBarItem;
