import React from 'react';
import { Text, View } from 'react-native';
import DividerLine from '../../components/View/DividerLine';
import SwitchView from '../../components/View/SwitchView';
import { LocalesModule } from '../../modules/LocalesModule';
import ServerModule from '../../modules/serverModule';
import BaseScreen from '../Base/baseScreen';
import BookListComponent from './bookListComponent';
import { CompetitionItemComponent } from './competitionItemComponent';
import { BookListInvitation, Invitation } from './invitation';
import { Competition } from './competition';
import styles from './invitationScreen_styles';
import UserInfoMiniComponent from './UserInfoMiniComponent';
import wx from 'weixin-js-sdk'
import { Ref } from '../../classes/ref';
import { UrlWeb } from '../../modules/UrlServer';
import AppColor from '../../classes/appColor';
import PlatformModule from '../../modules/PlatformModule';
import AppstoreComponent from '../Web/appstoreComponent';

const deferredLinkRoot = 'DL_';

export enum NotificationType {
    any = 0,
    comments,
    ratings,
    newFollowers,
    messages,
    playsOnMyBook,
    newChallenges,
    newEvaluations,
    newBook,
    invitation,
    broadcast,
    bestPlayer,
    competition,
    playSync,
    paidWriterInvitation,
}

export default class InvitationScreen extends BaseScreen {
    invitation?: Invitation;
    appId: string = 'wx2937501b22df671d';
    invitationId: string;
    isWechat: boolean = false;
    wxRef = new Ref();

    constructor(props: any) {
        super(props);
        this.invitationId = this.props.route.params.id;
        this.isLoading = true;
        this.loadInvitation(this.invitationId);
        this.isWechat = this.isInWechat();
        this.wxSign();
    }

    doWXSign(result: any) {
        wx.config({...result, debug: false, jsApiList: ['onMenuShareWeibo'], openTagList: ['wx-open-launch-app']});
        wx.ready(()=> {
            console.log('wx ready: ');
            const btn = document.getElementById('launch-btn');
            btn?.addEventListener('error', (e: any)=> {
                if (e.detail.errMsg == 'launch:fail') {
                    if ( PlatformModule.isIOSWeb() ) {
                        window.location.replace('https://apps.apple.com/us/app/hollyspeak/id6444904388');
                    } else if ( PlatformModule.isAndroidWeb() ) {
                        window.location.replace( "https://demo.com/download.html"); //android app下载地址
                    }
                }
            });
        });
        wx.error((e: any)=> {
            console.log('wx error: ', e);
        });
    }

    deferredLink() {
        const root = deferredLinkRoot;
        const link = this.getLink();
        ServerModule.apiCall('deferredLink/save', 'POST', {root,link}).then(()=>{});
    }

    getLink() {
        return '/inv/' + this.invitationId;
    }

    wxSign() {
        const url = UrlWeb + this.getLink();
        ServerModule.apiCall('wx/sign', 'POST', {url}).then((result: any)=>{
            this.doWXSign(result);
        });
    }

    loadInvitation(id: string) {
        ServerModule.apiCall('social/getShareLink/' + id, 'GET').then((json) => {
            this.isLoading = false;
            const notificationType = json?.result.notificationType;
            const item = json?.result?.item;
            if (item) {
                this.title = LocalesModule.formatString(LocalesModule.invite.invitation_from, item?.fromUser?.name || '');
                this.invitation = item;
                if(notificationType === NotificationType.paidWriterInvitation) {
                    this.deferredLink();
                }
            }
            this.refresh();
        });
    }

    getTitle() {
        return LocalesModule.formatString(LocalesModule.invite.message.playInvitation, this.invitation?.fromUser?.name || '');
    }

    getWXLaunchAppView() {
        // @ts-ignore style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        return <wx-open-launch-app ref={this.wxRef.ref} appid={this.appId} id={'launch-btn'} extinfo={this.invitationId}>
            <script type='text/wxtag-template'>
                <button style={{padding: 12, borderWidth: 0, borderRadius: 4, fontSize: 16, fontWeight: 'bold', backgroundColor: AppColor.primaryColor, color: AppColor.white}} >App 内打开</button>
            </script>
        {/* @ts-ignore */}
        </wx-open-launch-app>
    }

    isInWechat() {
        let wechat = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i);
        let judgeWechat = wechat?.[1].split('.');
        return  (judgeWechat && Number(judgeWechat[0]) >= 6) || false;
    }

    getMainView() {
        if (!this.invitation) {
            return <Text style={styles.wrong_link}> {LocalesModule.invite.wrong_link} </Text>
        }
        return (
            <View style={styles.container}>
                <View style={styles.messageRow}>
                    <UserInfoMiniComponent navigation={this.props.navigation} player={this.invitation.fromUser}
                                           style={styles.userInfo}/>
                    <Text style={styles.message}
                          numberOfLines={4}>
                        {this.invitation.message}
                    </Text>
                </View>
                <DividerLine/>
                <SwitchView first={Boolean((this.invitation as Competition).participants)} >
                    <CompetitionItemComponent competition={this.invitation as Competition} />
                    <BookListComponent parentScreen={this} editable={true}
                                       bookList={(this.invitation as BookListInvitation).bookList}/>
                </SwitchView>
                <SwitchView first={this.isWechat} >
                    <View style={styles.commandView}>
                        {this.getWXLaunchAppView()}
                    </View>
                    <View style={styles.downloadView}>
                        <Text style={styles.message}> {LocalesModule.invite.download_to_play} </Text>
                        <AppstoreComponent style={{alignSelf: 'center'}}/>
                    </View>
                </SwitchView>
            </View>
        );
    }
}
