import {Dimensions} from 'react-native';

export const DeviceScreenSize = Dimensions.get('window');
export const DeviceScreenWidth =
    DeviceScreenSize.width <= DeviceScreenSize.height ? DeviceScreenSize.width : DeviceScreenSize.height;
export const DeviceScreenHeight =
    DeviceScreenSize.width > DeviceScreenSize.height ? DeviceScreenSize.width : DeviceScreenSize.height;

export const TouchableActiveOpacity = 0.85;
export const NavigationBarHeight = 44;

export const VideoHWRatio = 1.0;
export const DefaultFont = 'HelveticaNeue-Medium';
