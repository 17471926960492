import React from 'react';
import {Text, View} from 'react-native';
import styles from './CBanner_styles';
import CIcon from '../Icon/CIcon';
import HideableView from '../View/HideableView';
import CImage from '../Image/CImage';
import AnimatedHideView from '../View/AnimatedHideView';
import {BaseComponent} from '../Base/baseComponent';

export enum BannerType {
    success,
    info,
    warning,
    error,
}

export interface CBannerData {
    type?: BannerType;
    icon?: string;
    text?: string;
    autoDismiss?: boolean;
}

export interface CBannerProps {
    hidden?: boolean;
    data?: CBannerData;
    onClose?: () => void;
}

class CBanner extends BaseComponent<CBannerProps> {
    state: any = {
        visible: true,
        data: undefined,
    };

    getBackgroundColor(): string | undefined {
        const type = this.props.data ? this.props.data.type : undefined;
        switch (type) {
            case BannerType.success:
                return '#89C341';
            case BannerType.error:
                return '#F83200';
            case BannerType.info:
                return '#3C86CE';
            case BannerType.warning:
                return '#F1B521';
        }
        return undefined;
    }

    render() {
        if (this.props.hidden || !this.props.data) return null;

        if (this.props.data != this.state.data) {
            this.state.data = this.props.data;
            this.state.visible = true;
        }

        if (this.props.data.autoDismiss && this.state.visible) {
            setTimeout(() => {
                if (this.state.visible) {
                    this.state.visible = false;
                    this.setState(this.state);
                }
            }, 4000);
        }

        return (
            <AnimatedHideView visible={this.state.visible} animationType={'opacity'} unmountOnHide={true}>
                <View style={[styles.container, {backgroundColor: this.getBackgroundColor()}]}>
                    <HideableView hidden={!this.props.data.icon}>
                        <CImage name={this.props.data.icon} style={styles.icon} />
                    </HideableView>
                    <Text style={styles.bannerText}>{this.props.data.text}</Text>
                    <CIcon style={styles.iconClose} name="EvilIcons/close" onPress={this.props.onClose} />
                </View>
            </AnimatedHideView>
        );
    }
}

export default CBanner;
