import React from 'react';
import {View} from 'react-native';
import {BaseComponent} from '../Base/baseComponent';
import {Rect} from '../../components/GEOM/geom';
import AppStyles from '../../screens/appStyles';

export const WebBigScreenWidthLimited = 1200;
export const WebSmallScreenWidthLimited = 760;

export interface MaxWidthLimitedViewProps {
    style?: any;
    limitedWidth?: number;
    children?: any;
}

class MaxWidthLimitedView extends BaseComponent<MaxWidthLimitedViewProps> {
    maxWidth = this.props.limitedWidth;
    widthToLimit?: number;
    layout?: Rect;

    onLayout(event: any) {
        if (this.layout?.width !== event.nativeEvent.layout?.width || this.layout?.height !== event.nativeEvent.layout?.height ) {
            this.layout = event.nativeEvent.layout;
            let widthLimit: number | undefined;
            if (this.layout && this.maxWidth && this.layout.width >= this.maxWidth) {
                widthLimit = this.maxWidth;
            } else {
                widthLimit = this.layout && this.layout.width;
            }
            if (widthLimit != this.widthToLimit) {
                this.widthToLimit = widthLimit;
                this.forceUpdate();
            }
        }
    }

    render() {
        if (this.props.limitedWidth) {
            const styles: any = {
                flex: 1,
                width: this.widthToLimit,
                alignSelf: 'center',
                alignItems: 'stretch',
            };
            return (
                <View style={[this.props.style, AppStyles.flex1]} onLayout={(event: any) => this.onLayout(event)}>
                    <View style={styles}>{this.props.children}</View>
                </View>
            );
        }
        return <View style={this.props.style}>{this.props.children}</View>;
    }
}

export default MaxWidthLimitedView;
