import React from 'react';
import {Text} from 'react-native';
import AppColor from '../../classes/appColor';

interface TitleTextProps {
    hidden?: boolean;
    text: string;
    style?: any;
}

class TitleText extends React.PureComponent<TitleTextProps> {
    render() {
        if (this.props.hidden) return null;

        const textStyle = {
            fontSize: 30,
            marginBottom: 30,
            textAlign: 'center',
            color: AppColor.almost_black,
            fontWeight: '500',
        };

        return <Text style={[textStyle, this.props.style]}>{this.props.text}</Text>;
    }
}

export default TitleText;
