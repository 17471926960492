import React, {PureComponent} from 'react';

interface SwitchViewProps {
    first?: boolean;
    hidden?: boolean;
    activeIndex?: number;
    children?: React.ReactNode;
}

export default class SwitchView extends PureComponent<SwitchViewProps> {
    constructor(props: SwitchViewProps) {
        super(props);
    }

    render() {
        if ( this.props.hidden || !this.props.children || (this.props.activeIndex && this.props.activeIndex < 0)) return null;
        const children: any = this.props.children;
        if (this.props.first && children.length > 1) {
            return children[0];
        }
        if (this.props.activeIndex === 0 || this.props.activeIndex) {
            if (!Array.isArray(children)) {
                return children;
            }
            if (this.props.activeIndex >= children.length) {
                return null;
            }
            return children[this.props.activeIndex];
        }
        return children.slice(1, children.length);
    }
}
