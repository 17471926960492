import {StyleSheet} from 'react-native';
import AppColor from '../../classes/appColor'

export const styles = StyleSheet.create({
    menuBar: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: AppColor.darkPrimaryColor,
    },
    menuItem: {
        color: '#FFFFCC',
        fontWeight: 'bold',
        margin: 10,
    },
    icon: {
        width: 40,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 8,
        marginBottom: 8,
    },
    tabItemStyle: {
        margin: 6,
    },
    label: {
        color: AppColor.headerInActiveTextColor,
        fontSize: 14,
        fontWeight: '600',
        paddingVertical: 6,
    },
    activeLabel: {
        color: AppColor.headerActiveTextColor,
        borderBottomWidth: 2,
        borderBottomColor: '#A76AEA',
    },
    help: {
        color: AppColor.headerInActiveTextColor,
        marginRight: 10,
    }
});
