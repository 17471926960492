import React, {PureComponent} from 'react';
import {View} from 'react-native';
import AppStyles from '../../screens/appStyles';
import CImage from '../Image/CImage';
import HideableView from './HideableView';
import AppColor from '../../classes/appColor';

interface DeletableViewProps {
    hidden?: boolean;
    isDeleteMode?: boolean;
    onDelete?: (event?: any) => void;
    style?: any;
    iconStyle?: any;
    childrenStyle?: any;
    center?: boolean;
    children?: any;
}

export default class DeletableView extends PureComponent<DeletableViewProps> {
    constructor(props: DeletableViewProps) {
        super(props);
    }

    render() {
        if(this.props.hidden) {
            return null;
        }
        const style = {
            width: 44,
            height: 44,
            justifyContent: 'center',
            alignItems: 'center',
            color: AppColor.red,
        };

        const deleteIconStyle: any = this.props.center
            ? {
                  position: 'absolute',
                  right: 0,
                  left: 0,
                  alignItems: 'center',
              }
            : {
                  position: 'absolute',
                  right: 0,
              };

        const childStyle = this.props.isDeleteMode ? [AppStyles.opacity07, AppStyles.flexR_center] : AppStyles.flexR_center;
        return (
            <View style={[AppStyles.flexR_center, this.props.style]}>
                <View style={[childStyle, this.props.childrenStyle]}>{this.props.children}</View>
                <HideableView hidden={!this.props.isDeleteMode}>
                    <View style={deleteIconStyle}>
                        <CImage
                            style={ [style, this.props.iconStyle] }
                            name="./Entypo/circle-with-cross"
                            onPress={(event) => this.props.onDelete && this.props.onDelete(event)}
                        />
                    </View>
                </HideableView>
            </View>
        );
    }
}
